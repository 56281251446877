import React, { Component } from "react";
import "../sass/CustomerIntro.scss";
import { withRouter } from "react-router-dom";
import Opportunity from "./Opportunity";
import Edit from "../images/EditIcon.svg";
import AccountsIcon from "../images/AccountsIcon.svg";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SendEmailIcon from "../images/AccountEmailIconBlack.svg";
import ArrowIcon from "../images/ArrowDown.svg";
import ArrowIconUp from "../images/ArrowUp.svg";
import ContactsNavIcon from "../images/AccountContactIconNew.svg";
import AccountTaskIcon from "../images/AccountTaskIconNew.svg";
import DealsNavIcon from "../images/AccountDealIconNew.svg";
import AccountActivityIcon from "../images/ActivityAccountIcon.svg";
import SaveButtonAccount from "../images/SaveButtonAccount.svg";
import EditIcon from "../images/EditIcon.svg";
import DeleteIconRed from "../images/DeleteIconRed.svg";
import CrossIcon from "../images/CrossIcon.svg";
import OppModal from "./OppModal";
import SendEmailModal from "./SendEmail/SendEmailModal";
import CreateContact from "./Contacts/CreateContact";
import Contact from "./Contacts/Contact";
import Collapsible from "react-collapsible";
import ReactTooltip from "react-tooltip";
import TaskCreateModal from "./TaskCreateModal";
import TaskTourDealCreate from "./Onboarding/TaskTourDealCreate";
import IntegrationRedirectModal from "./Integration/IntegrationRedirectModal";
import SourceChangeModal from "./Accounts/SourceChangeModal";
import axios from "axios";
import DocumentIcon from "../images/DocumentIcon.svg";
import DeleteIcon from "../images/DeleteIcon.svg";
import LaneIcon from "../images/lane.svg";
import DocumentQuickAccessIcon from "../images/DocumentQuickAccessIconNew.svg";
import { AsYouType } from "libphonenumber-js";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  authUserSet,
  companyOnboardingSet,
  setStoredRecordCollapsible,
  setStoredRecordSelectable,
  removeStoredRecordCollapsible,
  removeStoredRecordSelectable,
  fetchAllAccountSources,
} from "../redux";
import { compose } from "recompose";
import DeleteAttachmentModal from "./SendEmail/DeleteAttachmentModal";
import DisqualifyModal from "./DisqualifyModal";
import DeleteAccountModal from "./Accounts/DeleteAccountModal";
import CreateContactModal from "./CreateModal/CreateContactModal";
import CreateDealModal from "./CreateModal/CreateDealModal";
import {
  getCurrencyValue,
  isValidCurrency,
} from "../utils/Helper/reusableFunctions";
import { CurrencyList } from "../constants/currencylist";
import Pagination from "react-js-pagination";
import { Plus } from "lucide-react";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    width: "280px",
    outline: "#C0EAD8",
    minHeight: "30px",
    padding: "5px auto",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? "#C0EAD8"
        : null,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#C0EAD8",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "black",
    fontSize: "13px",
  }),
};

class CustomerIntro extends Component {
  state = {
    userData: {
      custom_fields: {},
    },
    customFieldsForAPI: {},
    opportunities: [],
    allFields: [],
    showOpportunityModal: false,
    showNewOpportunityModal: false,
    editing: false,
    name: null,
    email: null,
    formatted_phone: null,
    phone_ext: null,
    address: null,
    source: null,
    website: null,
    linkedin: null,
    zip: null,
    state: null,
    city: null,
    country: "United States",
    description: null,
    preferred_communication: [],
    customFields: {},
    status: null,
    showDisqualifyModal: false,
    showDeleteModal: false,
    disqualify_reason: null,
    account_owner: null,
    showEmailModal: false,
    showContactModal: false,
    showNewContactModal: false,
    windowWidth: window.innerWidth,
    isLoading: false,
    account_owner_id: null,
    sharedAccountOwners: [],
    tasks: [],
    users: [],
    tourComplete: false,
    showRedirectModal: false,
    integration: false,
    postMarkId: null,
    postMarkVerified: false,
    showInputField: null,
    showButtonModal: false,
    errorMessage: false,
    showSourceModal: false,
    selected: 0,
    showInfo: true,
    showInfoOf: "",
    documents: [],
    showDeleteAttachmentModal: false,
    documentId: null,
    showAddDocumentButton: true,
    contactEmailsArray: [],
    phoneValidationError: false,
    emailValidationError: false,
    showSaveEmailModal: false,
    showAllSequenceModal: false,
    customFieldRevenueTypeCheck: [],
    funnels: [],
    showAddDealButton: true,
    activeContactsPage: 1,
  };
  fileInput = React.createRef();
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
    this.setState({ tourComplete: true });
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };
  handleOpenNewOpportunityModal = () => {
    this.setState({ showNewOpportunityModal: true });
  };

  handleCloseNewOpportunityModal = () => {
    this.setState({ showNewOpportunityModal: false });
  };

  handleOpenEmailModal = () => {
    this.setState({
      showEmailModal: true,
      showInputField: null,
    });
  };

  handleCloseEmailModal = () => {
    this.setState({
      showEmailModal: false,
      showInputField: null,
    });
  };
  handleOpenDeleteAttachmentModal = (id) => {
    this.setState({
      showDeleteAttachmentModal: true,
      documentId: id,
    });
  };

  handleCloseDeleteAttachmentModal = () => {
    this.getDocuments();
    this.setState({
      showDeleteAttachmentModal: false,
    });
  };
  handleOpenRedirectModal = () => {
    this.setState({
      showRedirectModal: true,
    });
  };
  handleCloseRedirectModal = () => {
    this.setState({
      showRedirectModal: false,
      showInputField: null,
    });
  };
  handleOpenSourceModal = () => {
    this.setState({
      showSourceModal: true,
    });
  };
  handleCloseSourceModal = () => {
    this.setState({
      showSourceModal: false,
    });
  };
  handleOpenSaveEmailModal = () => {
    this.setState({
      showSaveEmailModal: true,
    });
  };

  handleCloseSaveEmailModal = () => {
    this.setState({
      showSaveEmailModal: false,
    });
  };
  handleOpenAllSequenceModal = () => {
    if (this.state.email === "" || this.state.email === null) {
      this.handleOpenSaveEmailModal();
    } else {
      this.setState({
        showSaveEmailModal: false,
        showAllSequenceModal: true,
      });
    }
  };
  handleCloseAllSequenceModal = () => {
    this.setState({
      showAllSequenceModal: false,
    });
  };
  handleOpenContactModal = () => {
    this.setState({
      showContactModal: true,
    });
  };

  handleCloseContactModal = () => {
    this.setState({
      showContactModal: false,
    });
  };
  handleOpenNewContactModal = () => {
    this.setState({
      showNewContactModal: true,
    });
  };

  handleCloseNewContactModal = () => {
    this.setState({
      showNewContactModal: false,
    });
  };
  handleOpenDisqualifyModal = () => {
    this.setState({
      showDisqualifyModal: true,
    });
  };

  handleCloseDisqualifyModal = () => {
    this.setState({
      showDisqualifyModal: false,
    });
  };
  handleOpenDeleteModal = () => {
    this.setState({
      showDeleteModal: true,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };
  handleOpenTaskModal = () => {
    this.props.setShowTaskModal(true);
  };

  handleCloseTaskModal = () => {
    this.props.setShowTaskModal(false);
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  onSelect = (key) => {
    this.setState({ selected: key });
  };
  getTasks = () => {
    axios({
      method: "GET",
      url: `/accounts/${this.props.customerId}/tasks`,
    }).then((res) => {
      this.setState({ tasks: res.data.tasks });
    });
  };
  getUsers = () => {
    axios({
      method: "GET",
      url: `/admin/users?account_id=${this.props.customerId}`,
    }).then((response) => {
      this.setState({
        users: response.data.users?.slice()?.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // Ignore case for comparison
          const nameB = b.name.toUpperCase(); // Ignore case for comparison

          if (nameA < nameB) {
            return -1; // Negative value: a should come before b
          }
          if (nameA > nameB) {
            return 1; // Positive value: b should come before a
          }
          return 0; // Return 0 for elements considered equal
        }),
      });
    });
  };
  getAllFunnels = () => {
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      this.setState({
        funnels: res.data.funnels,
      });
    });
  };
  getDocuments = () => {
    axios({
      method: "GET",
      url: `/attachments?account_id=${this.props.customerId}`,
    }).then((res) => this.setState({ documents: res.data.attachments }));
  };
  fetchAccountData = () => {
    axios({
      method: "GET",
      url: `/accounts/${this.props.customerId}`,
    }).then((res) => {
      const primaryAccountOwner = res.data.account.owners?.find(
        (owner) => owner.is_primary === true
      );
      this.setState({
        userData: res.data.account,
        name: res.data.account.name,
        email: res.data.account.email,
        formatted_phone: res.data.account.formatted_phone,
        phone_ext: res.data.account.phone_ext,
        address: res.data.account.address,
        source: res.data.account.source,
        city: res.data.account.city,
        website:
          res.data.account.website === null
            ? null
            : res.data.account.website.startsWith("https://") ||
              res.data.account.website.startsWith("http://")
            ? res.data.account.website
            : "https://" + res.data.account.website,
        linkedin:
          res.data.account.linkedin === null
            ? null
            : res.data.account.linkedin.startsWith("https://") ||
              res.data.account.linkedin.startsWith("http://")
            ? res.data.account.linkedin
            : "https://" + res.data.account.linkedin,
        zip:
          res.data.account.zip !== null
            ? res.data.account.zip.toString()
            : null,
        state: res.data.account.state,
        country:
          res.data.account.country === null
            ? "United States"
            : res.data.account.country,
        description: res.data.account.description,
        status: res.data.account.status,
        disqualify_reason: res.data.account.disqualify_reason,
        preferred_communication: (
          res.data.account.preferred_communication || []
        ).filter((com) => com !== ""),
        account_owner:
          res.data.account.owners.length === 0
            ? "Unassigned Leads"
            : primaryAccountOwner?.name,
        account_owner_id:
          res.data.account.owners.length === null
            ? ""
            : primaryAccountOwner?.owner_id,
        sharedAccountOwners: res.data.account.owners
          ?.filter((owner) => owner?.is_primary === false)
          ?.map((owner) => ({
            value: owner.owner_id,
            label: owner?.name,
          })),
        showButtonModal: false,
        showInputField: null,
        errorMessage: false,
        phoneValidationError: false,
        emailValidationError: false,
      });
      this.customFieldInitializer(res.data.account.custom_fields);
    });
  };
  getContactsEmailArray = () => {
    axios({
      url: `/accounts/${this.props.customerId}/contacts?_limit=100`,
      method: "GET",
    }).then((res) => {
      this.props.setContactEmailsArray(
        res.data.contacts?.filter((contact) => contact.email !== null)
      );
      this.setState({
        contactEmailsArray: res.data.contacts?.filter(
          (contact) => contact.email !== null
        ),
      });
    });
  };
  fetchShowInfoOf = () => {
    this.setState({
      showInfoOf: this.props.storedRecordSelectable.account,
    });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    // document.addEventListener("mousedown", this.handleClick);
    this.getTasks();
    this.getUsers();
    this.getDocuments();
    this.getContactsEmailArray();
    this.getAllFunnels();
    this.fetchShowInfoOf();
    axios({
      method: "GET",
      url: `/users/mail-provider`,
    }).then((res) => {
      this.props.setIntegration(res.data.user.mail_provider);
      this.setState({
        integration: res.data.user.mail_provider,
        postMarkId: res.data.user.postmark_signature_id,
        postMarkVerified: res.data.user.postmark_signature_verified,
      });
    });
    axios({
      method: "GET",
      url: `/accounts/${this.props.customerId}`,
    }).then((res) => {
      this.props.setAccountEmail(res.data.account.email);
      this.props.setAccountData(res.data.account);
      const primaryAccountOwner = res.data.account.owners?.find(
        (owner) => owner.is_primary === true
      );
      this.setState({
        userData: res.data.account,
        name: res.data.account.name,
        email: res.data.account.email,
        formatted_phone: res.data.account.formatted_phone,
        phone_ext: res.data.account.phone_ext,
        address: res.data.account.address,
        source: res.data.account.source,
        website:
          res.data.account.website === null
            ? null
            : res.data.account.website.startsWith("https://") ||
              res.data.account.website.startsWith("http://")
            ? res.data.account.website
            : "https://" + res.data.account.website,
        linkedin:
          res.data.account.linkedin === null
            ? null
            : res.data.account.linkedin.startsWith("https://") ||
              res.data.account.linkedin.startsWith("http://")
            ? res.data.account.linkedin
            : "https://" + res.data.account.linkedin,
        zip:
          res.data.account.zip !== null
            ? res.data.account.zip.toString()
            : null,
        state: res.data.account.state,
        country:
          res.data.account.country === null
            ? "United States"
            : res.data.account.country,
        city: res.data.account.city,
        description: res.data.account.description,
        status: res.data.account.status,
        disqualify_reason: res.data.account.disqualify_reason,
        preferred_communication: (
          res.data.account.preferred_communication || []
        ).filter((com) => com !== ""),
        account_owner:
          res.data.account.owners.length === 0
            ? "Unassigned Leads"
            : primaryAccountOwner?.name,
        account_owner_id:
          res.data.account.owners.length === null
            ? ""
            : primaryAccountOwner?.owner_id,
        sharedAccountOwners: res.data.account.owners
          ?.filter((owner) => owner?.is_primary === false)
          ?.map((owner) => ({
            value: owner.owner_id,
            label: owner?.name,
          })),
      });
      this.customFieldInitializer(res.data.account.custom_fields);
    });

    this.props.fetchAllOpportunities(this.props.customerId);
    this.props.fetchAllContacts();
  }
  customFieldInitializer = (initialValue) => {
    this.setState({
      allFields: this.props.allAccountCustomFields,
    });
    const customField = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const customFieldTypeCheck = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    const finalObject = { ...obj, ...initialValue };
    this.props.setAccountCustomFields(finalObject);
    this.setState((prevState) => ({
      customFields: finalObject,
      customFieldsForAPI: finalObject,
      customFieldRevenueTypeCheck: objTypeCheck,
    }));
  };
  editStart = () => {
    this.setState(
      {
        editing: !this.state.editing,
        errorMessage: false,
        phoneValidationError: false,
        emailValidationError: false,
      },
      () => {
        if (!this.state.editing) {
          this.fetchAccountData();
        }
      }
    );
  };
  editCancel = () => {
    this.fetchAccountData();
    this.setState({
      editing: false,
      phoneValidationError: false,
      emailValidationError: false,
      errorMessage: false,
    });
  };
  openFileSelect = () => this.fileInput.current.click();
  handleInputChange = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
    this.submitAttachment(event.target.files[0]);
  };
  showDealPortion = () => {
    this.setState({
      showInfo: true,
      showInfoOf: "deals",
      showAddDealButton: true,
    });
  };
  submitAttachment = (selectedFile) => {
    const { customerId } = this.props;
    const formData = new FormData();
    formData.append("attachment", selectedFile);
    formData.append("account_id", customerId);
    axios({
      method: "POST",
      url: `/attachments`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then((res) => {
      this.getDocuments();
      NotificationManager.success("Document added successfully.");
    });
  };
  preferredCommunicationChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.name === "preferred_communication") {
      if (e.target.checked === true) {
        this.setState((prevState) => ({
          preferred_communication: [
            ...this.state.preferred_communication.filter((com) => com !== ""),
            value,
          ],
        }));
      } else {
        this.setState({
          preferred_communication: this.state.preferred_communication.filter(
            (pc) => pc !== value
          ),
        });
      }
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value !== "" ? e.target.value : null,
    });
  };
  handlePhoneChangeHandler = (e) => {
    const { name, value } = e.target;
    const formatter = new AsYouType("US"); // Initialize AsYouType instance
    const formattedValue = formatter.input(value); // Format the input value
    this.setState({
      [name]: formattedValue !== "" ? formattedValue : null,
    });
  };
  countriesList = [
    "Afghanistan",
    "Aland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Barbuda",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Trty.",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Caicos Islands",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, Democratic Republic of the",
    "Cook Islands",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Futuna Islands",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard",
    "Herzegovina",
    "Holy See",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Jan Mayen Islands",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea",
    "Korea (Democratic)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "McDonald Islands",
    "Mexico",
    "Micronesia",
    "Miquelon",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "Nevis",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Principe",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Barthelemy",
    "Saint Helena",
    "Saint Kitts",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre",
    "Saint Vincent",
    "Samoa",
    "San Marino",
    "Sao Tome",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia",
    "South Sandwich Islands",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "The Grenadines",
    "Timor-Leste",
    "Tobago",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "US Minor Outlying Islands",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City State",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (British)",
    "Virgin Islands (US)",
    "Wallis",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];
  deleteAttachmentHandler = (id) => {
    axios({
      method: "DELETE",
      url: `/attachments/${id}`,
    })
      .then((res) => {
        this.handleCloseDeleteAttachmentModal();
        NotificationManager.success("Document deleted successfully.");
      })
      .catch((err) => NotificationManager.error("Failed to delete document."));
  };
  submitHandler = (e) => {
    e.preventDefault();
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      this.state.formatted_phone !== null &&
      this.state.formatted_phone !== "" &&
      !phoneValidator.test(this.state.formatted_phone)
    ) {
      this.setState({
        isLoading: false,
        phoneValidationError: true,
      });
      return false;
    }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      !emailValidator.test(this.state.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    if (!this.state.name) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    }
    this.setState({ isLoading: true });
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    axios({
      method: "PUT",
      url: `/accounts/${this.props.customerId}`,
      data: {
        name: this.state.name,
        state: this.state.state,
        country: this.state.country,
        zip: this.state.zip,
        city: this.state.city,
        address: this.state.address,
        source: this.state.source,
        email: this.state.email,
        formatted_phone: this.state.formatted_phone,
        phone_ext: this.state.phone_ext,
        website: this.state.website
          ? this.state.website.startsWith("https://") ||
            this.state.website.startsWith("http://")
            ? this.state.website
            : "https://" + this.state.website
          : null,
        linkedin: this.state.linkedin
          ? this.state.linkedin.startsWith("https://") ||
            this.state.linkedin.startsWith("http://")
            ? this.state.linkedin
            : "https://" + this.state.linkedin
          : null,
        description: this.state.description,
        preferred_communication: this.state.preferred_communication,
        custom_fields: customField,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "account",
          id: this.props.customerId,
          name: this.state.name,
          formatted_phone: this.state.formatted_phone,
        };
        this.props.setTab({ ...newTabData, edited: true });
        this.props.setCallTasks(!this.props.callTasks);
        NotificationManager.success("Account edited successfully.");
      })
      .then((response) => this.fetchAccountData())
      .then((response) =>
        this.setState({
          editing: false,
          isLoading: false,
          showButtonModal: false,
          showInputField: null,
          errorMessage: false,
          phoneValidationError: false,
          emailValidationError: false,
        })
      )
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing account.");
        }
      });
  };
  saveEmailSubmitHandler = (e) => {
    e.preventDefault();
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      this.state.formatted_phone !== null &&
      this.state.formatted_phone !== "" &&
      !phoneValidator.test(this.state.formatted_phone)
    ) {
      this.setState({
        isLoading: false,
        phoneValidationError: true,
      });
      return false;
    }
    if (
      this.state.email !== null &&
      this.state.email !== "" &&
      !emailValidator.test(this.state.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    if (!this.state.name) {
      this.setState({ errorMessage: true, isLoading: false });
      return false;
    }
    this.setState({ isLoading: true });
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    axios({
      method: "PUT",
      url: `/accounts/${this.props.customerId}`,
      data: {
        name: this.state.name,
        state: this.state.state,
        country: this.state.country,
        zip: this.state.zip,
        city: this.state.city,
        address: this.state.address,
        source: this.state.source,
        email: this.state.email,
        formatted_phone: this.state.formatted_phone,
        phone_ext: this.state.phone_ext,
        website: this.state.website
          ? this.state.website.startsWith("https://") ||
            this.state.website.startsWith("http://")
            ? this.state.website
            : "https://" + this.state.website
          : null,
        linkedin: this.state.linkedin
          ? this.state.linkedin.startsWith("https://") ||
            this.state.linkedin.startsWith("http://")
            ? this.state.linkedin
            : "https://" + this.state.linkedin
          : null,
        description: this.state.description,
        preferred_communication: this.state.preferred_communication,
        custom_fields: customField,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "account",
          id: this.props.customerId,
          name: this.state.name,
        };
        this.props.setTab({ ...newTabData, edited: true });
        NotificationManager.success("Account edited successfully.");
      })
      .then((response) => this.fetchAccountData())
      .then((response) =>
        this.setState({
          editing: false,
          isLoading: false,
          showButtonModal: false,
          showInputField: null,
          errorMessage: false,
          phoneValidationError: false,
          emailValidationError: false,
          showSaveEmailModal: false,
          showAllSequenceModal: true,
        })
      )
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing account.");
        }
      });
  };
  updateOwner = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    console.log(
      "check owners",
      this.state.account_owner_id,
      this.state.sharedAccountOwners
    );
    const sharedAccountOwners = !this.state.sharedAccountOwners
      ? []
      : this.state.sharedAccountOwners;
    axios({
      method: "POST",
      url: `/accounts/${this.props.customerId}/owners`,
      data: {
        owners: !this.state.account_owner_id
          ? []
          : sharedAccountOwners
              ?.map((user) => ({
                is_primary: false,
                owner_id: user.value,
              }))
              .concat([
                {
                  is_primary: true,
                  owner_id: this.state.account_owner_id,
                },
              ]),
      },
    })
      .then((res) => {
        this.fetchAccountData();
        NotificationManager.success("Account Owner(s) changed.");
        this.setState({
          editing: false,
          isLoading: false,
          showButtonModal: false,
          showInputField: null,
        });
      })
      .catch((err) => {
        NotificationManager.error("Error changing owner.");
        this.setState({ isLoading: false });
      });
  };
  customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
      }));
    }
  };
  customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: null },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: null,
        },
      }));
    }
  };
  customFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [fieldName]: selectedOptions,
      },
      customFieldsForAPI: {
        ...prevState.customFieldsForAPI,
        [fieldName]:
          ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldDateChangeHandler = (type, date) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [type]: date },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [type]: date },
    }));
  };
  showInput = (fieldName, fieldLabel, fieldType, fieldChoices, singleEdit) => {
    let inputType;
    if (fieldType === "text") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        />
      );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        />
      );
    } else if (fieldType === "revenue") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="text"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldRevenueChangeHandler}
        />
      );
    } else if (fieldType === "paragraph") {
      inputType = (
        <textarea
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          style={{ fontFamily: "inherit" }}
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        ></textarea>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <input
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          type="number"
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        />
      );
    } else if (fieldType === "dropdown") {
      inputType = (
        <select
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          name={fieldName}
          value={this.state.customFields[fieldName]}
          onChange={this.customFieldChangeHandler}
        >
          <option selected disabled>
            -Select-
          </option>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return <option key={id}>{value}</option>;
          })}
        </select>
      );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div>
          <input
            type="checkbox"
            onFocus={() =>
              this.setState(singleEdit && { showInputField: fieldName })
            }
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
            onChange={this.customFieldCheckBoxChangeHandler}
            checked={this.state.customFields[fieldName]}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left" }}>
                <input
                  onFocus={() =>
                    this.setState(singleEdit && { showInputField: fieldName })
                  }
                  type="radio"
                  style={{ display: "inline-block", textAlign: "left" }}
                  name={fieldName}
                  value={value}
                  onChange={this.customFieldChangeHandler}
                  checked={this.state.customFields[fieldName] === value}
                  id={fieldName + value}
                />
                <label
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <DatePicker
          autoFocus={singleEdit}
          className={singleEdit && "customerIntro-inputField"}
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          name={fieldName}
          selected={Date.parse(this.state.customFields[fieldName])}
          value={Date.parse(this.state.customFields[fieldName])}
          onChange={(date) =>
            this.customFieldDateChangeHandler(fieldName, date)
          }
          dateFormat={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/MM/yyyy"
              : "MM/dd/yyyy"
          }
          placeholderText={
            this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/mm/yyyy"
              : "mm/dd/yyyy"
          }
          autoComplete="off"
        />
      );
    } else if (fieldType === "multi_select_dropdown") {
      let options = [];
      if (
        fieldName === "cf_equipment_type" ||
        fieldName === "cf_equipment_types"
      ) {
        options = this.props.allEquipmentTypes?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else if (fieldName === "cf_special_requirements") {
        options = this.props.allSpecialRequirements?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else if (fieldName === "cf_modes") {
        options = this.props.allModes?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else {
        options = fieldChoices.map((choice) => {
          const { value } = choice;
          return { value, label: value };
        });
      }
      inputType = (
        <Select
          autoFocus={singleEdit}
          className={
            singleEdit
              ? "customerIntro-inputField basic-multi-select"
              : "basic-multi-select"
          }
          onFocus={() =>
            this.setState(singleEdit && { showInputField: fieldName })
          }
          closeMenuOnSelect={false}
          value={this.state.customFields[fieldName]}
          isMulti
          name={fieldName}
          styles={colourStyles}
          options={options}
          classNamePrefix="select"
          onChange={(selectedOption) =>
            this.customFieldMultiDropdownChangeHandler(
              fieldName,
              selectedOption
            )
          }
        />
      );
    }
    return inputType;
  };
  statusChangeHandler = (e) => {
    const { value } = e.target;
    if (value === "disqualified") {
      return this.handleOpenDisqualifyModal();
    }
    this.setState({
      status: value,
    });
    axios({
      method: "PUT",
      url: `/accounts/${this.props.customerId}/status`,
      data: {
        status: value,
      },
    })
      .then((res) => {
        NotificationManager.success("Successfully changed status");
        this.fetchAccountData();
      })
      .catch((err) => {
        NotificationManager.error("Error changing status");
      });
  };
  accountOwnerMultiDropdownChangeHandler = (selectedOptions) => {
    this.setState((prevState) => ({
      sharedAccountOwners: selectedOptions,
    }));
  };
  checkDeletedUserHandle = (userIds, selectedUser) => {
    if (!userIds.includes(this.state.account_owner_id) && !selectedUser) {
      return (
        <option value={this.state.account_owner_id} selected disabled>
          {this.state.account_owner}
        </option>
      );
    }
  };

  render() {
    const { opportunities, contacts, totalContacts } = this.props;
    const { showInputField } = this.state;
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const currency = this.props.companyOnboarding?.currency;
    const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
    const userIds = this.props.allUsers?.map((user) => user.id);
    const selectedUser = this.props.allUsers?.find(
      (user) => parseInt(user.id) === parseInt(this.state.account_owner_id)
    );
    return (
      <div className="customerInfo">
        <div className="customerInfo__Intro">
          <div className="customerInfo-icon">
            <img
              src={AccountsIcon}
              alt=""
              width="17px"
              style={{ transform: "translateY(9px)" }}
            />
          </div>
          <NotificationContainer />
          <div className="customerInfo-info">
            <h2 className="c-type">
              Account{" "}
              <i
                className="fa fa-circle"
                style={{ fontSize: "4px", transform: "translateY(-3px)" }}
              ></i>{" "}
              <select
                className={
                  this.state.status?.toLowerCase() === "client"
                    ? "c-icon-won"
                    : this.state?.status === "disqualified"
                    ? "c-icon-lost"
                    : "c-icon-3"
                }
                name="status"
                value={this.state?.status}
                onChange={(e) => {
                  this.statusChangeHandler(e);
                }}
              >
                <option value="" disabled selected>
                  -
                </option>
                <option value="disqualified">Disqualified</option>
                {this.props.allAccountStatuses
                  ?.filter((stat) => stat.name !== "disqualified")
                  .map((stat) => (
                    <option
                      value={stat.name}
                      style={{ textTransform: "capitalize" }}
                      key={stat.id}
                    >
                      {stat.name}
                    </option>
                  ))}
              </select>
              <span style={{ color: "#F36363" }}>
                {" "}
                {this.state?.status === "disqualified"
                  ? "(" + this.state?.disqualify_reason + ")"
                  : null}
              </span>
            </h2>
            <h1 className="c-name">
              {this.state.editing ? (
                <input
                  type="text"
                  name="name"
                  value={this.state?.name}
                  onChange={this.changeHandler}
                  className="c-name-input"
                  autoComplete="off"
                  style={{ width: "120%" }}
                />
              ) : this.state.userData.name &&
                this.state.userData.name.length > 30 ? (
                this.state.userData.name.substring(0, 30) + "..."
              ) : (
                this.state.userData.name
              )}
              <button
                onClick={this.editStart}
                className="account-editButton-pencil"
              >
                <img src={Edit} alt="Edit" height="13" />
              </button>
            </h1>
            <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {this.state.errorMessage === true &&
              (this.state.name === null || this.state.name === "")
                ? "Name is required"
                : null}
            </div>
          </div>
          {/*account_owner*/}
          <div
            className="c-hoverDesc-div"
            onClick={() => this.setState({ showInputField: "account_owner" })}
          >
            <h5 className="c-title-act">
              {this.props.companyOnboarding
                ?.is_multiple_account_owner_enabled && "Primary "}
              Account Owner
            </h5>
            {showInputField === "account_owner" ? (
              <form onSubmit={this.updateOwner} style={{ display: "flex" }}>
                <select
                  autoFocus
                  className="customerIntro-inputField"
                  onFocus={() =>
                    this.setState({ showInputField: "account_owner" })
                  }
                  name="account_owner_id"
                  value={this.state.account_owner_id}
                  onChange={this.changeHandler}
                >
                  {(this.props.authUser.permissions.includes(
                    "crm:unassigned:leads:viewer"
                  ) ||
                    this.props.authUser.role === "SUPERADMIN") &&
                    this.state.account_owner_id !== "" && (
                      <option value="">Unassigned Leads</option>
                    )}
                  {this.checkDeletedUserHandle(userIds, selectedUser)}
                  {this.props.allUsers?.map((user) => {
                    const { id, name } = user;
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                {!this.state.isLoading && (
                  <button
                    type="button"
                    className="c-hoverDesc-button-close"
                    onClick={this.fetchAccountData}
                  >
                    <img src={CrossIcon} alt="Save" width="8" />
                  </button>
                )}
                {this.state.isLoading ? (
                  <div class="spinning-loader"></div>
                ) : (
                  <button
                    type="submit"
                    className="c-hoverDesc-button-save"
                    disabled={this.state.isLoading}
                  >
                    <img src={SaveButtonAccount} alt="Save" width="20" />
                  </button>
                )}
              </form>
            ) : (
              <p className="c-desc c-hoverDesc">
                {this.state.account_owner}{" "}
                <button
                  className="c-hoverDesc-button"
                  onClick={() =>
                    this.setState({ showInputField: "account_owner" })
                  }
                >
                  <img src={EditIcon} alt="Edit" width="13" />
                </button>
              </p>
            )}
          </div>
          {/*sharedAccountOwners*/}
          {this.props.companyOnboarding?.is_multiple_account_owner_enabled &&
            this.state.account_owner_id && (
              <div
                className="c-hoverDesc-div"
                onClick={() =>
                  this.setState({ showInputField: "sharedAccountOwners" })
                }
              >
                <h5 className="c-title-act">Shared Account Owner</h5>
                {showInputField === "sharedAccountOwners" ? (
                  <form onSubmit={this.updateOwner} style={{ display: "flex" }}>
                    <Select
                      value={this.state.sharedAccountOwners}
                      closeMenuOnSelect={false}
                      menuPlacement={this.state.menuOpen}
                      isMulti
                      name={"accountOwner"}
                      styles={colourStyles}
                      options={this.props.allUsers
                        ?.filter(
                          (user) =>
                            user.id?.toString() !==
                            this.state.account_owner_id?.toString()
                        )
                        ?.map((user) => ({
                          value: user.id,
                          label: user.name,
                        }))}
                      placeholder="-Select-"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      required={true}
                      onChange={(selectedOption) =>
                        this.accountOwnerMultiDropdownChangeHandler(
                          selectedOption
                        )
                      }
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.sharedAccountOwners
                      ?.map((owner) => owner?.label)
                      ?.join(", ")}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "sharedAccountOwners" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
            )}
          {/*website*/}
          <div
            className="c-hoverDesc-div"
            onClick={() => this.setState({ showInputField: "website" })}
          >
            <h5 className="c-title-act">Website</h5>
            {showInputField === "website" ? (
              <form onSubmit={this.submitHandler} style={{ display: "flex" }}>
                <input
                  autoFocus
                  className="customerIntro-inputField"
                  onFocus={() => this.setState({ showInputField: "website" })}
                  type="text"
                  name="website"
                  value={this.state.website}
                  onChange={this.changeHandler}
                />
                {!this.state.isLoading && (
                  <button
                    type="button"
                    className="c-hoverDesc-button-close"
                    onClick={this.fetchAccountData}
                  >
                    <img src={CrossIcon} alt="Save" width="8" />
                  </button>
                )}
                {this.state.isLoading ? (
                  <div class="spinning-loader"></div>
                ) : (
                  <button
                    type="submit"
                    className="c-hoverDesc-button-save"
                    disabled={this.state.isLoading}
                  >
                    <img src={SaveButtonAccount} alt="Save" width="20" />
                  </button>
                )}
              </form>
            ) : (
              <p className="c-desc c-hoverDesc">
                {this.state.website === null ? null : (
                  <a
                    href={this.state.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="c-desc"
                    style={{ cursor: "pointer", color: "#4A9876" }}
                  >
                    {this.state.website && this.state.website.length > 40
                      ? this.state.website.substring(0, 39) + "..."
                      : this.state.website}
                  </a>
                )}{" "}
                <button
                  className="c-hoverDesc-button"
                  onClick={() => this.setState({ showInputField: "website" })}
                >
                  <img src={EditIcon} alt="Edit" width="13" />
                </button>
              </p>
            )}
          </div>
          {/*linkedin*/}
          <div
            className="c-hoverDesc-div"
            onClick={() => this.setState({ showInputField: "linkedin" })}
          >
            <h5 className="c-title-act">Linkedin</h5>
            {showInputField === "linkedin" ? (
              <form onSubmit={this.submitHandler} style={{ display: "flex" }}>
                <input
                  autoFocus
                  className="customerIntro-inputField"
                  onFocus={() => this.setState({ showInputField: "linkedin" })}
                  type="text"
                  name="linkedin"
                  value={this.state.linkedin}
                  onChange={this.changeHandler}
                />
                {!this.state.isLoading && (
                  <button
                    type="button"
                    className="c-hoverDesc-button-close"
                    onClick={this.fetchAccountData}
                  >
                    <img src={CrossIcon} alt="Save" width="8" />
                  </button>
                )}
                {this.state.isLoading ? (
                  <div class="spinning-loader"></div>
                ) : (
                  <button
                    type="submit"
                    className="c-hoverDesc-button-save"
                    disabled={this.state.isLoading}
                  >
                    <img src={SaveButtonAccount} alt="Save" width="20" />
                  </button>
                )}
              </form>
            ) : (
              <p className="c-desc c-hoverDesc">
                {this.state.linkedin === null ? null : (
                  <a
                    href={this.state.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="c-desc"
                    style={{ cursor: "pointer", color: "#4A9876" }}
                  >
                    {this.state.linkedin && this.state.linkedin.length > 40
                      ? this.state.linkedin.substring(0, 39) + "..."
                      : this.state.linkedin}
                  </a>
                )}{" "}
                <button
                  className="c-hoverDesc-button"
                  onClick={() => this.setState({ showInputField: "linkedin" })}
                >
                  <img src={EditIcon} alt="Edit" width="13" />
                </button>
              </p>
            )}
          </div>
          {this.props.showTaskModal && (
            <TaskCreateModal
              accountId={this.props.customerId}
              showTaskModal={this.props.showTaskModal}
              setShowTaskModal={this.handleOpenTaskModal}
              handleCloseModal={this.handleCloseTaskModal}
              customerName={this.props.customerName}
              opportunities={opportunities}
              contacts={contacts}
              getTasks={this.getTasks}
              users={this.state.users}
              existingUser={this.props.authUser.id}
              keyValue="CustomerIntro"
            />
          )}
          <ReactTooltip multiline={true} />
          <div className="accountsActionButtonDiv">
            <label
              htmlFor="AccountActivityForm"
              className="account-Logactivity-button"
              data-tip="Add Activity"
            >
              <img src={AccountActivityIcon} alt="" style={{ width: "16px" }} />
            </label>
            <button
              type="button"
              onClick={this.handleOpenTaskModal}
              data-tip="Add Task"
              className="accountsActionButtonDiv-buttons"
            >
              <img src={AccountTaskIcon} alt="" style={{ width: "16px" }} />
              <div>Task</div>
            </button>
            <button
              type="button"
              onClick={
                this.state?.status === "disqualified"
                  ? () => {
                      NotificationManager.error(
                        "The account must be marked as qualified to add deals"
                      );
                    }
                  : this.props.isDealFieldsModified
                  ? this.handleOpenNewOpportunityModal
                  : this.handleOpenModal
              }
              data-tip="Add Deal"
              className="accountsActionButtonDiv-buttons"
            >
              <img src={DealsNavIcon} alt="" style={{ width: "16px" }} />
              <div>Deal</div>
            </button>
            <button
              type="button"
              onClick={
                this.state?.status === "disqualified"
                  ? () => {
                      NotificationManager.error(
                        "The account must be marked as qualified to add deals"
                      );
                    }
                  : this.props.isContactFieldsModified
                  ? this.handleOpenNewContactModal
                  : this.handleOpenContactModal
              }
              data-tip="Add Contact"
              className="accountsActionButtonDiv-buttons"
            >
              <img src={ContactsNavIcon} alt="" style={{ width: "16px" }} />
              <div>Contact</div>
            </button>
            <button
              type="button"
              for="file-upload"
              onClick={this.openFileSelect}
              data-tip="Add Document"
              className="accountsActionButtonDiv-buttons"
            >
              <img
                src={DocumentQuickAccessIcon}
                alt=""
                style={{ width: "16px", height: "17px" }}
              />
              <div>Document</div>
            </button>
          </div>
          <div style={{ display: "flex" }}>
            <button
              type="button"
              onClick={
                this.state.integration !== null ||
                (this.state.postMarkId !== null && this.state.postMarkVerified)
                  ? this.handleOpenEmailModal
                  : this.handleOpenRedirectModal
              }
              className="button-md button-outline-green button-outline-black-hover"
              style={{
                height: "36px",
                padding: "0 16px",
                fontWeight: "500",
                background: "#ffffff",
                color: "#000",
                border: "1px solid #000",
                width: "100%",
                borderRadius: "50px",
                marginRight: "15px",
              }}
            >
              <img
                src={SendEmailIcon}
                alt=""
                style={{
                  width: "16px",
                  marginRight: "5px",
                  transform: "translateY(2px)",
                }}
              />
              &nbsp;Send Email
            </button>
            {this.props.companyOnboarding?.industry === "Freight Brokerage" && (
              <button
                type="button"
                onClick={() => {
                  this.props.setAddLane(!this.props.addLane);
                  this.props.setLaneMode("create");
                }}
                className="button-md button-outline-green button-outline-black-hover"
                style={{
                  height: "36px",
                  padding: "0 16px",
                  fontWeight: "500",
                  background: "#fff",
                  border: "1px solid #000",
                  color: "#000",
                  width: "100%",
                  borderRadius: "50px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={LaneIcon}
                    alt=""
                    style={{
                      width: "16px",
                      marginRight: "5px",
                    }}
                  />
                  &nbsp;
                  <div>Add Lane</div>
                </div>
              </button>
            )}
          </div>
          {this.state.editing && (
            <button
              type="button"
              className="accountDeleteButton"
              onClick={this.handleOpenDeleteModal}
            >
              <img src={DeleteIconRed} alt="Delete" width="18px" />
            </button>
          )}
          <div className="customer-shortcut">
            <span
              className={
                this.state.showInfo && this.state.showInfoOf === "deals"
                  ? "customer-shortcut-label-active"
                  : "customer-shortcut-label"
              }
              onClick={() =>
                this.setState(() => {
                  this.state.showInfoOf === "deals" && this.state.showInfo
                    ? this.props.removeStoredRecordSelectable({
                        type: "account",
                        id: "deals",
                      })
                    : this.props.setStoredRecordSelectable({
                        type: "account",
                        id: "deals",
                      });
                  return {
                    showInfo:
                      this.state.showInfoOf === "deals" && this.state.showInfo
                        ? false
                        : true,
                    showInfoOf: "deals",
                    showAddDealButton: true,
                  };
                })
              }
            >
              Deals ({opportunities.length})
            </span>
            <span
              className={
                this.state.showInfo && this.state.showInfoOf === "contacts"
                  ? "customer-shortcut-label-active"
                  : "customer-shortcut-label"
              }
              onClick={() =>
                this.setState(() => {
                  this.state.showInfoOf === "contacts" && this.state.showInfo
                    ? this.props.removeStoredRecordSelectable({
                        type: "account",
                        id: "contacts",
                      })
                    : this.props.setStoredRecordSelectable({
                        type: "account",
                        id: "contacts",
                      });
                  return {
                    showInfo:
                      this.state.showInfoOf === "contacts" &&
                      this.state.showInfo
                        ? false
                        : true,
                    showInfoOf: "contacts",
                  };
                })
              }
            >
              Contacts ({totalContacts})
            </span>
            <span
              className={
                this.state.showInfo && this.state.showInfoOf === "docs"
                  ? "customer-shortcut-label-active"
                  : "customer-shortcut-label"
              }
              onClick={() =>
                this.setState(() => {
                  this.state.showInfoOf === "docs" && this.state.showInfo
                    ? this.props.removeStoredRecordSelectable({
                        type: "account",
                        id: "docs",
                      })
                    : this.props.setStoredRecordSelectable({
                        type: "account",
                        id: "docs",
                      });
                  return {
                    showInfo:
                      this.state.showInfoOf === "docs" && this.state.showInfo
                        ? false
                        : true,
                    showInfoOf: "docs",
                  };
                })
              }
            >
              Documents ({this.state.documents.length})
            </span>
          </div>
        </div>
        {this.state.showInfo && this.state.showInfoOf === "contacts" && (
          <div className="customerInfo__opportunity" id="CustomerIntroContacts">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              overflowWhenOpen="initial"
              open={true}
              trigger={
                <div>
                  Contacts ({totalContacts})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="c-title-main-1">Contacts ({totalContacts})</h4>
                  <button
                    onClick={
                      this.state?.status === "disqualified"
                        ? () => {
                            NotificationManager.error(
                              "The account must be marked as qualified to add contacts"
                            );
                          }
                        : this.props.isContactFieldsModified
                        ? this.handleOpenNewContactModal
                        : this.handleOpenContactModal
                    }
                    className="button-sm"
                    style={{ transform: "translateY(-6px)" }}
                  >
                    Add Contact
                    <Plus
                      size={14}
                      strokeWidth={3}
                      color="#62CA9D"
                      style={{
                        transform: "translateY(2px)",
                        marginLeft: "5px",
                      }}
                    />
                  </button>
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
            >
              {contacts.length > 2 ? (
                <div style={{ height: "200px", overflowY: "auto" }}>
                  {contacts?.map((contact) => {
                    return (
                      <Contact
                        customerId={this.props.customerId}
                        name={contact.name}
                        detail={contact.role}
                        type={contact.stage_name}
                        key={contact.id}
                        {...contact}
                      />
                    );
                  })}
                </div>
              ) : (
                <div>
                  {contacts?.map((contact) => {
                    return (
                      <Contact
                        customerId={this.props.customerId}
                        name={contact.name}
                        detail={contact.role}
                        type={contact.stage_name}
                        key={contact.id}
                        {...contact}
                      />
                    );
                  })}
                </div>
              )}
              <Pagination
                activePage={this.state.activeContactsPage}
                itemsCountPerPage={50}
                totalItemsCount={totalContacts}
                pageRangeDisplayed={5}
                onChange={(page) => {
                  this.setState({ activeContactsPage: page });
                  this.props.fetchAllContacts(page);
                }}
                itemClass="page-item-new"
                linkClass="page-link"
                hideDisabled
              />
            </Collapsible>
          </div>
        )}
        {/*Deals */}
        {this.state.showInfo && this.state.showInfoOf === "deals" && (
          <div className="customerInfo__opportunity" id="CustomerIntroDeals">
            <Collapsible
              className="collapseHeaderAccount-deal"
              classParentString="collapseHeaderAccount-deal"
              open={true}
              overflowWhenOpen="initial"
              trigger={
                <div>
                  Deals ({opportunities.length})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="c-title-main-1">
                    Deals ({opportunities.length})
                  </h4>
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
              onClosing={() => this.setState({ showAddDealButton: false })}
              onOpening={() => this.setState({ showAddDealButton: true })}
            >
              {this.state.showAddDealButton && (
                <button
                  onClick={
                    this.state?.status === "disqualified"
                      ? () => {
                          NotificationManager.error(
                            "The account must be marked as qualified to add deals"
                          );
                        }
                      : this.props.isDealFieldsModified
                      ? this.handleOpenNewOpportunityModal
                      : this.handleOpenModal
                  }
                  className="button-sm"
                  style={{ position: "absolute", top: "9px", right: "8px" }}
                >
                  Add Deal
                  <Plus
                    size={14}
                    color="#62CA9D"
                    strokeWidth={3}
                    style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                  />
                </button>
              )}
              {opportunities.length > 2 ? (
                <div style={{ height: "200px", overflowY: "auto" }}>
                  {opportunities?.map((opportunity) => {
                    const close_date =
                      opportunity.close_date &&
                      moment(opportunity.close_date, "YYYY-MM-DD").format(
                        "MMMM DD, YYYY"
                      );
                    return (
                      <Opportunity
                        customerId={this.props.customerId}
                        name={opportunity.name}
                        detail={close_date}
                        type={opportunity.stage_name}
                        key={opportunity.id}
                        funnels={this.state.funnels}
                        authUser={this.props.authUser}
                        fetchAllOpportunities={() =>
                          this.props.fetchAllOpportunities(
                            this.props.customerId
                          )
                        }
                        users={this.state.users}
                        getConfetti={this.props.getConfetti}
                        opportunityData={{ ...opportunity }}
                        {...opportunity}
                      />
                    );
                  })}
                </div>
              ) : (
                <div>
                  {opportunities?.map((opportunity) => {
                    const close_date =
                      opportunity.close_date &&
                      moment(opportunity.close_date, "YYYY-MM-DD").format(
                        "MMMM DD, YYYY"
                      );
                    return (
                      <Opportunity
                        customerId={this.props.customerId}
                        name={opportunity.name}
                        detail={close_date}
                        type={opportunity.stage_name}
                        key={opportunity.id}
                        funnels={this.state.funnels}
                        authUser={this.props.authUser}
                        fetchAllOpportunities={() =>
                          this.props.fetchAllOpportunities(
                            this.props.customerId
                          )
                        }
                        getConfetti={this.props.getConfetti}
                        users={this.state.users}
                        opportunityData={{ ...opportunity }}
                        {...opportunity}
                      />
                    );
                  })}
                </div>
              )}
            </Collapsible>
          </div>
        )}
        {/*Documnets */}
        {this.state.showInfo && this.state.showInfoOf === "docs" && (
          <div className="customerInfo__opportunity">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              overflowWhenOpen="initial"
              open={true}
              onOpen={() => this.setState({ showAddDocumentButton: true })}
              onClose={() => this.setState({ showAddDocumentButton: false })}
              triggerDisabled={this.state.documents.length === 0 ? true : false}
              trigger={
                <div>
                  Documents ({this.state.documents.length})
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="c-title-main-1">
                    Documents ({this.state.documents.length})
                  </h4>
                  {this.state.showAddDocumentButton && (
                    <button
                      type="button"
                      onClick={this.openFileSelect}
                      for="file-upload"
                      className="button-sm"
                      style={{ transform: "translateY(-6px)" }}
                    >
                      Add Documents
                      <Plus
                        size={14}
                        strokeWidth={3}
                        color="#62CA9D"
                        style={{
                          transform: "translateY(2px)",
                          marginLeft: "5px",
                        }}
                      />
                    </button>
                  )}
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
            >
              <div
                style={
                  this.state.documents.length > 2
                    ? { height: "200px", overflowY: "auto" }
                    : {}
                }
              >
                {this.state.documents?.map((doc) => (
                  <div className="documentStorage" key={doc.id}>
                    <a
                      href={doc.s3_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="schedule__items-1" data-tip={doc.name}>
                        <ReactTooltip />
                        <span
                          className="com-2"
                          style={{ background: "#D2DCFF" }}
                        >
                          <img
                            src={DocumentIcon}
                            alt=""
                            style={{
                              transform: "translate(0.5px,0px)",
                              background: "#D2DCFF",
                              width: "18px",
                            }}
                          />
                        </span>
                        <div className="schedule__heading">
                          <span className="name-opp">
                            {doc.name.length > 25
                              ? doc.name.substring(0, 25) + "..."
                              : doc.name}
                          </span>
                        </div>
                        <div className="schedule__desc">
                          {doc.created_at &&
                            moment(doc.created_at).format("MMMM DD, YYYY")}{" "}
                          .{" "}
                          {doc.size > 1024
                            ? (doc.size / 1024).toFixed(2) + "MB"
                            : doc.size + "KB"}
                        </div>
                      </div>
                    </a>
                    <button
                      className="schedule__delete"
                      type="button"
                      onClick={() =>
                        this.handleOpenDeleteAttachmentModal(doc.id)
                      }
                    >
                      <img src={DeleteIcon} alt="x" width="16px" />
                    </button>
                  </div>
                ))}
              </div>
            </Collapsible>
          </div>
        )}
        <input
          style={{ display: "none" }}
          id="file-upload"
          type="file"
          ref={this.fileInput}
          onChange={this.handleInputChange}
        />
        <TaskTourDealCreate
          tourComplete={this.state.tourComplete}
          restart={this.props.restart}
        />
        {this.state.showDisqualifyModal && (
          <DisqualifyModal
            showOpportunityModal={this.state.showDisqualifyModal}
            setShowOpportunityModal={this.handleOpenDisqualifyModal}
            handleCloseModal={this.handleCloseDisqualifyModal}
            customerName={this.props.customerName}
            customerId={this.props.customerId}
            fetchAccountData={this.fetchAccountData}
            status={this.props.status}
          />
        )}
        {this.state.showDeleteModal && (
          <DeleteAccountModal
            showOpportunityModal={this.state.showDeleteModal}
            setShowOpportunityModal={this.handleOpenDeleteModal}
            handleCloseModal={this.handleCloseDeleteModal}
            customerName={this.props.customerName}
            customerId={this.props.customerId}
            tabIndex={this.props.tabIndex}
            deleteTab={this.props.deleteTab}
          />
        )}
        <SendEmailModal
          showOpportunityModal={this.state.showEmailModal}
          setShowOpportunityModal={this.handleOpenEmailModal}
          handleCloseModal={this.handleCloseEmailModal}
          email={this.state.email}
          accountId={this.props.customerId}
          loadActivities={this.props.loadActivities}
          contactEmailsArray={this.state.contactEmailsArray}
          replyToEmail={this.state.email ? [this.state.email] : []}
          fromEmail={this.props.authUser.email}
          idSequence={this.props.customerId}
          type={"account_id"}
          companyId={this.props.authUser.company_id}
          integration={this.state.integration}
          recordChangeHandler={this.changeHandler}
          recordSubmitHandler={this.saveEmailSubmitHandler}
          recorderLoading={this.state.isLoading}
          emailValidationError={this.state.emailValidationError}
          showSaveEmailModal={this.state.showSaveEmailModal}
          showAllSequenceModal={this.state.showAllSequenceModal}
          handleOpenAllSequenceModal={this.handleOpenAllSequenceModal}
          handleCloseAllSequenceModal={this.handleCloseAllSequenceModal}
          handleOpenSaveEmailModal={this.handleOpenSaveEmailModal}
          handleCloseSaveEmailModal={this.handleCloseSaveEmailModal}
          allUsers={this.props.allUsers}
          recordName={this.state.name}
        />
        {this.state.showRedirectModal && (
          <IntegrationRedirectModal
            showOpportunityModal={this.state.showRedirectModal}
            setShowOpportunityModal={this.handleOpenRedirectModal}
            handleCloseModal={this.handleCloseRedirectModal}
          />
        )}
        {this.state.editing ? (
          <form onSubmit={this.submitHandler}>
            <div
              className="customerInfo__Info"
              style={{ height: "53vh", overflowY: "auto" }}
            >
              <div className="collapseHeaderAccount-edit">
                <h4 className="c-title-main">Account Info</h4>
                <h5 className="c-title-act">Email</h5>
                <input
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.changeHandler}
                />
                {this.state.emailValidationError && (
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    Please enter a valid email
                  </div>
                )}
                <h5 className="c-title-act">Phone</h5>
                <input
                  type="tel"
                  name="formatted_phone"
                  // pattern="[0-9\-]+"
                  value={this.state.formatted_phone}
                  onChange={this.changeHandler}
                />
                {this.state.phoneValidationError && (
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    Please enter a valid phone number
                  </div>
                )}
                <h5 className="c-title-act">Phone Extension</h5>
                <input
                  type="text"
                  name="phone_ext"
                  value={this.state.phone_ext}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">Address</h5>
                <input
                  type="text"
                  name="address"
                  value={this.state.address}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">City</h5>
                <input
                  type="text"
                  name="city"
                  value={this.state.city}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">State</h5>
                {this.state.country !== "United States" ? (
                  <input
                    type="text"
                    name="state"
                    value={this.state.state}
                    onChange={this.changeHandler}
                  />
                ) : (
                  <select
                    name="state"
                    value={this.state.state}
                    onChange={this.changeHandler}
                  >
                    <option value="" selected disabled>
                      -Select-
                    </option>
                    <option>AL</option>
                    <option>AK</option>
                    <option>AZ</option>
                    <option>AR</option>
                    <option>CA</option>
                    <option>CO</option>
                    <option>CT</option>
                    <option>DE</option>
                    <option>FL</option>
                    <option>GA</option>
                    <option>HI</option>
                    <option>ID</option>
                    <option>IL</option>
                    <option>IN</option>
                    <option>IA</option>
                    <option>KS</option>
                    <option>KY</option>
                    <option>LA</option>
                    <option>ME</option>
                    <option>MD</option>
                    <option>MA</option>
                    <option>MI</option>
                    <option>MN</option>
                    <option>MS</option>
                    <option>MO</option>
                    <option>MT</option>
                    <option>NE</option>
                    <option>NV</option>
                    <option>NH</option>
                    <option>NJ</option>
                    <option>NM</option>
                    <option>NY</option>
                    <option>NC</option>
                    <option>ND</option>
                    <option>OH</option>
                    <option>OK</option>
                    <option>OR</option>
                    <option>PA</option>
                    <option>RI</option>
                    <option>SC</option>
                    <option>SD</option>
                    <option>TN</option>
                    <option>TX</option>
                    <option>UT</option>
                    <option>VT</option>
                    <option>VA</option>
                    <option>WA</option>
                    <option>WV</option>
                    <option>WI</option>
                    <option>WY</option>
                  </select>
                )}
                <h5 className="c-title-act">Zip/Postal</h5>
                <input
                  type="text"
                  name="zip"
                  value={this.state.zip}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">Country</h5>
                <select
                  name="country"
                  value={this.state.country}
                  onChange={this.changeHandler}
                >
                  {this.countriesList.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
                <h5 className="c-title-act">Preferred Communication</h5>
                <div className="customerInfo__AddInfo-icon">
                  <div className="modal-radioButton">
                    <label
                      htmlFor="call"
                      style={
                        this.state?.preferred_communication.includes("call")
                          ? {
                              border: "2px solid #4A9876",
                              outline: "none",
                              color: "#4A9876",
                              padding: "3px 12px 1px 12px",
                              borderRadius: "15px",
                              marginRight: "13px",
                              background: "#ffffff",
                              fontWeight: "normal",
                              fontSize: "13px",
                              lineHeight: "14px",
                              height: "16px",
                            }
                          : {
                              color: "#555555",
                              padding: "3px 12px 1px 12px",
                              borderRadius: "15px",
                              border: "none",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "13px",
                              lineHeight: "14px",
                              height: "16px",
                            }
                      }
                    >
                      Call
                    </label>
                    <input
                      type="checkbox"
                      name="preferred_communication"
                      value="call"
                      id="call"
                      className="radio-button"
                      onChange={this.preferredCommunicationChangeHandler}
                    />
                    <label
                      htmlFor="email"
                      style={
                        this.state?.preferred_communication.includes("email")
                          ? {
                              border: "2px solid #4A9876",
                              outline: "none",
                              color: "#4A9876",
                              padding: "3px 12px 1px 12px",
                              borderRadius: "15px",
                              marginRight: "13px",
                              background: "#ffffff",
                              fontWeight: "normal",
                              fontSize: "13px",
                              lineHeight: "14px",
                              height: "16px",
                            }
                          : {
                              color: "#555555",
                              padding: "3px 12px 1px 12px",
                              borderRadius: "15px",
                              border: "none",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "13px",
                              lineHeight: "14px",
                              height: "16px",
                            }
                      }
                    >
                      Email
                    </label>
                    <input
                      type="checkbox"
                      name="preferred_communication"
                      value="email"
                      id="email"
                      className="radio-button"
                      onChange={this.preferredCommunicationChangeHandler}
                    />
                    <label
                      htmlFor="text"
                      style={
                        this.state?.preferred_communication.includes("text")
                          ? {
                              border: "2px solid #4A9876",
                              outline: "none",
                              color: "#4A9876",
                              padding: "3px 12px 1px 12px",
                              borderRadius: "15px",
                              marginRight: "13px",
                              background: "#ffffff",
                              fontWeight: "normal",
                              fontSize: "13px",
                              lineHeight: "14px",
                              height: "16px",
                            }
                          : {
                              color: "#555555",
                              padding: "3px 12px 1px 12px",
                              borderRadius: "15px",
                              border: "none",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "13px",
                              lineHeight: "14px",
                              height: "16px",
                            }
                      }
                    >
                      Text
                    </label>
                    <input
                      type="checkbox"
                      name="preferred_communication"
                      value="text"
                      id="text"
                      className="radio-button"
                      onChange={this.preferredCommunicationChangeHandler}
                    />
                  </div>
                </div>
                <h5 className="c-title-act">Source</h5>
                <div className="customerInfo__AddInfo-icon">
                  <select
                    name="source"
                    value={this.state.source}
                    onChange={this.changeHandler}
                  >
                    <option hidden>-select-</option>
                    <option selected disabled>
                      -select-
                    </option>
                    {this.props.allAccountSources?.map((source) => (
                      <option key={source.id} value={source.name}>
                        {source.name}
                      </option>
                    ))}
                  </select>
                </div>
                <h5 className="c-title-act">Website</h5>
                <input
                  type="text"
                  name="website"
                  value={this.state.website}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">Linkedin</h5>
                <input
                  type="text"
                  name="linkedin"
                  value={this.state.linkedin}
                  onChange={this.changeHandler}
                />
                <h5 className="c-title-act">Description</h5>
                <textarea
                  name="description"
                  value={this.state.description}
                  onChange={this.changeHandler}
                  style={{ whiteSpace: "pre-wrap" }}
                ></textarea>
              </div>
              {this?.state?.allFields?.map((field) => {
                const { label, id, fields, meta } = field;
                return (
                  <div key={id}>
                    {meta === null && (
                      <div className="collapseHeaderAccount-edit">
                        <h4 className="c-title-main-1">{label}</h4>
                        <div>
                          {fields.map((data) => {
                            const { id, label, type, choices, name } = data;
                            return (
                              <div key={id}>
                                <h5 className="c-title-act">{label}</h5>
                                <div key={id}>
                                  {this.showInput(name, label, type, choices)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="customerIntroStateChange">
              <p>Save changes to fields?</p>
              <button
                type="button"
                onClick={this.editCancel}
                className="modal__clear-1 customerIntroStateChange-cancel"
              >
                Cancel
              </button>
              <button
                disabled={this.state.isLoading}
                type="button"
                onClick={this.submitHandler}
                className="modal__addButton-1 customerIntroStateChange-save"
              >
                {this.state.isLoading ? "Saving" : "Save"}
              </button>
            </div>
          </form>
        ) : (
          <div className="customerInfo__Info" id="CustomerIntroAccountInfo">
            <Collapsible
              className="collapseHeaderAccount"
              classParentString="collapseHeaderAccount"
              overflowWhenOpen="initial"
              trigger={
                <div>
                  Account Info
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen={
                <div>
                  Account Info
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "4%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIconUp} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerClassName="c-title-main"
              triggerOpenedClassName="c-title-main"
              onOpening={() =>
                this.props.setStoredRecordCollapsible({
                  type: "account",
                  id: "default",
                })
              }
              onClosing={() =>
                this.props.removeStoredRecordCollapsible({
                  type: "account",
                  id: "default",
                })
              }
              open={this.props.storedRecordCollapsible.account.includes(
                "default"
              )}
            >
              {/*address*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "address" })}
              >
                <h5 className="c-title-act">Address</h5>
                {showInputField === "address" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "address" })
                      }
                      type="text"
                      name="address"
                      value={this.state.address}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.address}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "address" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*city*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "city" })}
              >
                <h5 className="c-title-act">City</h5>
                {showInputField === "city" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() => this.setState({ showInputField: "city" })}
                      type="text"
                      name="city"
                      value={this.state.city}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.city}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() => this.setState({ showInputField: "city" })}
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*state*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "state" })}
              >
                <h5 className="c-title-act">State</h5>
                {showInputField === "state" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    {this.state.country !== "United States" ? (
                      <input
                        autoFocus
                        className="customerIntro-inputField"
                        onFocus={() =>
                          this.setState({ showInputField: "state" })
                        }
                        type="text"
                        name="state"
                        value={this.state.state}
                        onChange={this.changeHandler}
                      />
                    ) : (
                      <select
                        className="customerIntro-inputField"
                        onFocus={() =>
                          this.setState({ showInputField: "state" })
                        }
                        name="state"
                        value={this.state.state}
                        onChange={this.changeHandler}
                      >
                        <option value="" selected disabled>
                          -Select-
                        </option>
                        <option>AL</option>
                        <option>AK</option>
                        <option>AZ</option>
                        <option>AR</option>
                        <option>CA</option>
                        <option>CO</option>
                        <option>CT</option>
                        <option>DE</option>
                        <option>FL</option>
                        <option>GA</option>
                        <option>HI</option>
                        <option>ID</option>
                        <option>IL</option>
                        <option>IN</option>
                        <option>IA</option>
                        <option>KS</option>
                        <option>KY</option>
                        <option>LA</option>
                        <option>ME</option>
                        <option>MD</option>
                        <option>MA</option>
                        <option>MI</option>
                        <option>MN</option>
                        <option>MS</option>
                        <option>MO</option>
                        <option>MT</option>
                        <option>NE</option>
                        <option>NV</option>
                        <option>NH</option>
                        <option>NJ</option>
                        <option>NM</option>
                        <option>NY</option>
                        <option>NC</option>
                        <option>ND</option>
                        <option>OH</option>
                        <option>OK</option>
                        <option>OR</option>
                        <option>PA</option>
                        <option>RI</option>
                        <option>SC</option>
                        <option>SD</option>
                        <option>TN</option>
                        <option>TX</option>
                        <option>UT</option>
                        <option>VT</option>
                        <option>VA</option>
                        <option>WA</option>
                        <option>WV</option>
                        <option>WI</option>
                        <option>WY</option>
                      </select>
                    )}
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.state}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() => this.setState({ showInputField: "state" })}
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*zip*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "zip" })}
              >
                <h5 className="c-title-act">Zip/Postal</h5>
                {showInputField === "zip" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() => this.setState({ showInputField: "zip" })}
                      type="text"
                      name="zip"
                      value={this.state.zip}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.zip}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() => this.setState({ showInputField: "zip" })}
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*country*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "country" })}
              >
                <h5 className="c-title-act">Country</h5>
                {showInputField === "country" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <select
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "country" })
                      }
                      name="country"
                      value={this.state.country}
                      onChange={this.changeHandler}
                    >
                      {this.countriesList.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.country}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "country" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*formatted_phone*/}
              <div
                className="c-hoverDesc-div"
                onClick={() =>
                  this.setState({ showInputField: "formatted_phone" })
                }
              >
                <h5 className="c-title-act">Phone</h5>
                {showInputField === "formatted_phone" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "formatted_phone" })
                      }
                      type="tel"
                      name="formatted_phone"
                      value={this.state.formatted_phone}
                      onChange={this.handlePhoneChangeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc  c-hoverDesc">
                    <a
                      target="_blank"
                      data-stringify-link={`tel:${this.state.formatted_phone
                        ?.toString()
                        ?.split("")
                        ?.filter(
                          (num) =>
                            num !== "-" &&
                            num !== " " &&
                            num !== "." &&
                            num !== "(" &&
                            num !== ")"
                        )
                        ?.join("")}`}
                      delay="150"
                      data-sk="tooltip_parent"
                      href={`tel:${this.state.formatted_phone
                        ?.toString()
                        ?.split("")
                        ?.filter(
                          (num) =>
                            num !== "-" &&
                            num !== " " &&
                            num !== "." &&
                            num !== "(" &&
                            num !== ")"
                        )
                        ?.join("")}`}
                      rel="noopener noreferrer"
                      tabindex="-1"
                      data-remove-tab-index="true"
                    >
                      {this.state.formatted_phone}
                    </a>{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "formatted_phone" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
                {this.state.phoneValidationError && (
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    Please enter a valid phone number
                  </div>
                )}
              </div>
              {/*phone_ext*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "phone_ext" })}
              >
                <h5 className="c-title-act">Phone Extension</h5>
                {showInputField === "phone_ext" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "phone_ext" })
                      }
                      type="text"
                      name="phone_ext"
                      value={this.state.phone_ext}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc  c-hoverDesc">
                    {this.state.phone_ext}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "phone_ext" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*email*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "email" })}
              >
                <h5 className="c-title-act">Email</h5>
                {showInputField === "email" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <input
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() => this.setState({ showInputField: "email" })}
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.changeHandler}
                    />
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.email !== null ? (
                      <span
                        style={{
                          color: "#4A9876",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={
                          this.state.integration !== null ||
                          (this.state.postMarkId !== null &&
                            this.state.postMarkVerified)
                            ? this.handleOpenEmailModal
                            : this.handleOpenRedirectModal
                        }
                      >
                        {this.state.email}
                      </span>
                    ) : (
                      this.state.email
                    )}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() => this.setState({ showInputField: "email" })}
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {this.state.emailValidationError && (
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  Please enter a valid email
                </div>
              )}
              {/*source*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "source" })}
              >
                <h5 className="c-title-act">
                  Source{" "}
                  <button
                    type="button"
                    onClick={this.handleOpenSourceModal}
                    className="account-sourceButton"
                    data-tip="Add a new source to track where a client came from."
                  >
                    +
                  </button>
                </h5>
                {showInputField === "source" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <div className="customerInfo__AddInfo-icon">
                      <select
                        className="customerIntro-inputField"
                        onFocus={() =>
                          this.setState({ showInputField: "source" })
                        }
                        name="source"
                        value={this.state.source}
                        onChange={this.changeHandler}
                      >
                        <option hidden>-select-</option>
                        <option selected disabled>
                          -select-
                        </option>
                        {this.props.allAccountSources?.map((source) => (
                          <option key={source.id} value={source.name}>
                            {source.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p className="c-desc c-hoverDesc">
                    {this.state.source}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({ showInputField: "source" })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
              {/*preferred_communication*/}
              <div
                className="c-hoverDesc-div"
                onClick={() =>
                  this.setState({ showInputField: "preferred_communication" })
                }
              >
                <h5 className="c-title-act">Preferred Communication</h5>
                {showInputField === "preferred_communication" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <div className="customerInfo__AddInfo-icon">
                      <div className="modal-radioButton">
                        <label
                          htmlFor="call"
                          style={
                            this.state?.preferred_communication.includes("call")
                              ? {
                                  border: "2px solid #4A9876",
                                  outline: "none",
                                  color: "#4A9876",
                                  padding: "3px 12px 1px 12px",
                                  borderRadius: "15px",
                                  marginRight: "13px",
                                  background: "#ffffff",
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  lineHeight: "14px",
                                  height: "16px",
                                }
                              : {
                                  color: "#555555",
                                  padding: "3px 12px 1px 12px",
                                  borderRadius: "15px",
                                  border: "none",
                                  background: "#F4F6F5",
                                  marginRight: "13px",
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  lineHeight: "14px",
                                  height: "16px",
                                }
                          }
                        >
                          Call
                        </label>
                        <input
                          type="checkbox"
                          name="preferred_communication"
                          value="call"
                          id="call"
                          className="radio-button"
                          onChange={this.preferredCommunicationChangeHandler}
                        />
                        <label
                          htmlFor="email"
                          style={
                            this.state?.preferred_communication.includes(
                              "email"
                            )
                              ? {
                                  border: "2px solid #4A9876",
                                  outline: "none",
                                  color: "#4A9876",
                                  padding: "3px 12px 1px 12px",
                                  borderRadius: "15px",
                                  marginRight: "13px",
                                  background: "#ffffff",
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  lineHeight: "14px",
                                  height: "16px",
                                }
                              : {
                                  color: "#555555",
                                  padding: "3px 12px 1px 12px",
                                  borderRadius: "15px",
                                  border: "none",
                                  background: "#F4F6F5",
                                  marginRight: "13px",
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  lineHeight: "14px",
                                  height: "16px",
                                }
                          }
                        >
                          Email
                        </label>
                        <input
                          type="checkbox"
                          name="preferred_communication"
                          value="email"
                          id="email"
                          className="radio-button"
                          onChange={this.preferredCommunicationChangeHandler}
                        />
                        <label
                          htmlFor="text"
                          style={
                            this.state?.preferred_communication.includes("text")
                              ? {
                                  border: "2px solid #4A9876",
                                  outline: "none",
                                  color: "#4A9876",
                                  padding: "3px 12px 1px 12px",
                                  borderRadius: "15px",
                                  marginRight: "13px",
                                  background: "#ffffff",
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  lineHeight: "14px",
                                  height: "16px",
                                }
                              : {
                                  color: "#555555",
                                  padding: "3px 12px 1px 12px",
                                  borderRadius: "15px",
                                  border: "none",
                                  background: "#F4F6F5",
                                  marginRight: "13px",
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  lineHeight: "14px",
                                  height: "16px",
                                }
                          }
                        >
                          Text
                        </label>
                        <input
                          type="checkbox"
                          name="preferred_communication"
                          value="text"
                          id="text"
                          className="radio-button"
                          onChange={this.preferredCommunicationChangeHandler}
                        />
                      </div>
                    </div>
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <div className="customerInfo__AddInfo-icon c-hoverDesc">
                    {this.state.preferred_communication.map((pc) => (
                      <span
                        className="c-icon-1"
                        style={{
                          border: "2px solid #4A9876",
                          outline: "none",
                          color: "#4A9876",
                          padding: "3px 12px 1px 12px",
                          borderRadius: "15px",
                          marginRight: "13px",
                          background: "#ffffff",
                          fontWeight: "normal",
                          fontSize: "13px",
                          lineHeight: "14px",
                          height: "16px",
                          textTransform: "capitalize",
                        }}
                        key={pc}
                      >
                        {pc === "email"
                          ? "Email"
                          : pc === "call"
                          ? "Call"
                          : pc === "text"
                          ? "Text"
                          : pc}
                      </span>
                    ))}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({
                          showInputField: "preferred_communication",
                        })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </div>
                )}
              </div>
              {/*description*/}
              <div
                className="c-hoverDesc-div"
                onClick={() => this.setState({ showInputField: "description" })}
              >
                <h5 className="c-title-act">Description</h5>
                {showInputField === "description" ? (
                  <form
                    onSubmit={this.submitHandler}
                    style={{ display: "flex" }}
                  >
                    <textarea
                      autoFocus
                      className="customerIntro-inputField"
                      onFocus={() =>
                        this.setState({ showInputField: "description" })
                      }
                      name="description"
                      value={this.state.description}
                      onChange={this.changeHandler}
                      style={{ whiteSpace: "pre-wrap" }}
                    ></textarea>
                    {!this.state.isLoading && (
                      <button
                        type="button"
                        className="c-hoverDesc-button-close"
                        onClick={this.fetchAccountData}
                      >
                        <img src={CrossIcon} alt="Save" width="8" />
                      </button>
                    )}
                    {this.state.isLoading ? (
                      <div class="spinning-loader"></div>
                    ) : (
                      <button
                        type="submit"
                        className="c-hoverDesc-button-save"
                        disabled={this.state.isLoading}
                      >
                        <img src={SaveButtonAccount} alt="Save" width="20" />
                      </button>
                    )}
                  </form>
                ) : (
                  <p
                    className="c-desc c-hoverDesc"
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      marginBottom: "15px",
                      whiteSpace: "pre-wrap",
                      height: "auto",
                    }}
                  >
                    {this.state.description}{" "}
                    <button
                      className="c-hoverDesc-button"
                      onClick={() =>
                        this.setState({
                          showInputField: "description",
                        })
                      }
                    >
                      <img src={EditIcon} alt="Edit" width="13" />
                    </button>
                  </p>
                )}
              </div>
            </Collapsible>
            {this.state?.allFields?.map((field) => {
              const { label, id, fields, meta } = field;
              return (
                <div key={id} id={id}>
                  {meta === null && (
                    <div>
                      <Collapsible
                        className="collapseHeaderAccount"
                        classParentString="collapseHeaderAccount"
                        overflowWhenOpen="initial"
                        onOpening={() =>
                          this.props.setStoredRecordCollapsible({
                            type: "account",
                            id,
                          })
                        }
                        onClosing={() =>
                          this.props.removeStoredRecordCollapsible({
                            type: "account",
                            id,
                          })
                        }
                        open={this.props.storedRecordCollapsible.account.includes(
                          id
                        )}
                        trigger={
                          <div>
                            {label}
                            <button
                              type="button"
                              style={{
                                background: "transparent",
                                padding: "3px 10px",
                                border: "none",
                                borderRadius: "5px",
                                color: "white",
                                outline: "none",
                                cursor: "pointer",
                                position: "absolute",
                                top: "30%",
                                right: "2%",
                              }}
                            >
                              <img src={ArrowIcon} alt="" height="10px" />
                            </button>
                          </div>
                        }
                        triggerWhenOpen={
                          <div>
                            {label}
                            <button
                              type="button"
                              style={{
                                background: "transparent",
                                padding: "3px 10px",
                                border: "none",
                                borderRadius: "5px",
                                color: "white",
                                outline: "none",
                                cursor: "pointer",
                                position: "absolute",
                                top: "4%",
                                right: "2%",
                              }}
                            >
                              <img src={ArrowIconUp} alt="" height="10px" />
                            </button>
                          </div>
                        }
                        triggerClassName="c-title-main"
                        triggerOpenedClassName="c-title-main"
                      >
                        <div>
                          {fields?.map((data) => {
                            const { id, label, type, name, choices } = data;
                            return type === "date" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchAccountData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p className="c-desc c-hoverDesc">
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null ||
                                    this?.state?.userData?.custom_fields[
                                      name
                                    ] === undefined
                                      ? ""
                                      : moment(
                                          this?.state?.userData?.custom_fields[
                                            name
                                          ]
                                        ).format(dateFormat)}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : type === "revenue" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchOpportunitiesData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p className="c-desc c-hoverDesc">
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null
                                      ? ""
                                      : currencySymbol.symbol +
                                        getCurrencyValue(
                                          this?.state?.userData?.custom_fields[
                                            name
                                          ]
                                        )?.toLocaleString("en-US")}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : type === "multi_select_dropdown" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchAccountData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p className="c-desc c-hoverDesc">
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null
                                      ? ""
                                      : this?.state?.userData?.custom_fields[
                                          name
                                        ] &&
                                        this?.state?.userData?.custom_fields[
                                          name
                                        ]
                                          .replace(/;/g, ", ")
                                          .substr(1)
                                          .slice(0, -2)}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : type === "paragraph" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchAccountData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p
                                    className="c-desc c-hoverDesc"
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "16px",
                                      marginBottom: "15px",
                                      whiteSpace: "pre-wrap",
                                      height: "auto",
                                    }}
                                  >
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null
                                      ? ""
                                      : this?.state?.userData?.custom_fields[
                                          name
                                        ]}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : type === "hyperlink" ? (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchAccountData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p
                                    className="c-desc c-hoverDesc"
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "16px",
                                      marginBottom: "15px",
                                      whiteSpace: "pre-wrap",
                                      height: "auto",
                                    }}
                                  >
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null ? (
                                      ""
                                    ) : (
                                      <a
                                        href={
                                          this?.state?.userData?.custom_fields[
                                            name
                                          ]?.startsWith("https://") ||
                                          this?.state?.userData?.custom_fields[
                                            name
                                          ]?.startsWith("http://")
                                            ? this?.state?.userData
                                                ?.custom_fields[name]
                                            : "https://" +
                                              this?.state?.userData
                                                ?.custom_fields[name]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="c-desc"
                                        style={{
                                          cursor: "pointer",
                                          color: "#4A9876",
                                        }}
                                      >
                                        {this?.state?.userData?.custom_fields[
                                          name
                                        ] &&
                                        this?.state?.userData?.custom_fields[
                                          name
                                        ].length > 40
                                          ? this?.state?.userData?.custom_fields[
                                              name
                                            ].substring(0, 39) + "..."
                                          : this?.state?.userData
                                              ?.custom_fields[name]}
                                      </a>
                                    )}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            ) : (
                              <div
                                key={id}
                                className="c-hoverDesc-div"
                                onClick={() =>
                                  this.setState({ showInputField: name })
                                }
                              >
                                <h5 className="c-title-act">{label}</h5>
                                {showInputField === name ? (
                                  <form
                                    onSubmit={this.submitHandler}
                                    style={{ display: "flex" }}
                                  >
                                    {this.showInput(
                                      name,
                                      label,
                                      type,
                                      choices,
                                      true
                                    )}
                                    {!this.state.isLoading && (
                                      <button
                                        type="button"
                                        className="c-hoverDesc-button-close"
                                        onClick={this.fetchAccountData}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="Save"
                                          width="8"
                                        />
                                      </button>
                                    )}
                                    {this.state.isLoading ? (
                                      <div class="spinning-loader"></div>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="c-hoverDesc-button-save"
                                        disabled={this.state.isLoading}
                                      >
                                        <img
                                          src={SaveButtonAccount}
                                          alt="Save"
                                          width="20"
                                        />
                                      </button>
                                    )}
                                  </form>
                                ) : (
                                  <p className="c-desc c-hoverDesc">
                                    {this?.state?.userData?.custom_fields[
                                      name
                                    ] === null
                                      ? ""
                                      : this?.state?.userData?.custom_fields[
                                          name
                                        ]}{" "}
                                    <button
                                      className="c-hoverDesc-button"
                                      onClick={() =>
                                        this.setState({
                                          showInputField: name,
                                        })
                                      }
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        width="13"
                                      />
                                    </button>
                                  </p>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Collapsible>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
        {this.props.showOpportunityModal && (
          <OppModal
            customerId={this.props.customerId}
            showOpportunityModal={this.props.showOpportunityModal}
            setShowOpportunityModal={this.handleOpenModal}
            handleCloseModal={this.handleCloseModal}
            customerName={this.props.customerName}
            fetchAllOpportunitiesAgain={() =>
              this.props.fetchAllOpportunities(this.props.customerId)
            }
            email={this.state.email}
            formatted_phone={this.state.formatted_phone}
            website={this.state.website}
            fromRecord={true}
            showDealPortion={this.showDealPortion}
          />
        )}
        <CreateDealModal
          customerId={this.props.customerId}
          showOpportunityModal={this.state.showNewOpportunityModal}
          setShowOpportunityModal={this.handleOpenNewOpportunityModal}
          handleCloseModal={this.handleCloseNewOpportunityModal}
          customerName={this.props.customerName}
          fetchAllOpportunitiesAgain={() =>
            this.props.fetchAllOpportunities(this.props.customerId)
          }
          email={this.state.email}
          formatted_mobile={this.state.formatted_phone}
          website={this.state.website}
          fromRecord={true}
          showDealPortion={this.showDealPortion}
        />
        {this.state.showDeleteAttachmentModal && (
          <DeleteAttachmentModal
            showOpportunityModal={this.state.showDeleteAttachmentModal}
            setShowOpportunityModal={this.handleOpenDeleteAttachmentModal}
            handleCloseModal={this.handleCloseDeleteAttachmentModal}
            deleteAttachmentHandler={this.deleteAttachmentHandler}
            documentId={this.state?.documentId}
          />
        )}
        {this.state.showContactModal && (
          <CreateContact
            showOpportunityModal={this.state.showContactModal}
            setShowOpportunityModal={this.handleOpenContactModal}
            handleCloseModal={this.handleCloseContactModal}
            accountId={this.props.customerId}
            accountName={this.state.name}
          />
        )}
        {this.state.showNewContactModal && (
          <CreateContactModal
            showOpportunityModal={this.state.showNewContactModal}
            setShowOpportunityModal={this.handleOpenNewContactModal}
            handleCloseModal={this.handleCloseNewContactModal}
            accountId={this.props.customerId}
            accountName={this.state.name}
          />
        )}
        {this.state.showSourceModal && (
          <SourceChangeModal
            showOpportunityModal={this.state.showSourceModal}
            setShowOpportunityModal={this.handleOpenSourceModal}
            handleCloseModal={this.handleCloseSourceModal}
            fetchAccountData={this.fetchAccountData}
            getSources={this.props.fetchAllAccountSources}
            allAccountSources={this.props.allAccountSources}
          />
        )}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
    isContactFieldsModified: state.allContactFields.isModified,
    isDealFieldsModified: state.allDealFields.isModified,
    storedRecordCollapsible: state.storedRecordCollapsible.fields,
    storedRecordSelectable: state.storedRecordSelectable.fields,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allUsers: state.allUsers.users,
    allAccountSources: state.allAccountSources.accountSources,
    allAccountStatuses: state.allAccountStatuses.accountStatuses,
    allAccountCustomFields: state.allAccountCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    setStoredRecordCollapsible: (data) =>
      dispatch(setStoredRecordCollapsible(data)),
    setStoredRecordSelectable: (data) =>
      dispatch(setStoredRecordSelectable(data)),
    removeStoredRecordCollapsible: (data) =>
      dispatch(removeStoredRecordCollapsible(data)),
    removeStoredRecordSelectable: (data) =>
      dispatch(removeStoredRecordSelectable(data)),
    fetchAllAccountSources: (data) => dispatch(fetchAllAccountSources(data)),
  };
};

export default compose(withRouter, connect(MSP, MDP))(CustomerIntro);
