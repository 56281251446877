import React, { Component } from "react";
import XLSX from "xlsx";
import axios from "axios";
import { BASE_URL } from "../../config";
import _ from "lodash";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import TaskTourImport from "../Onboarding/TaskTourImport";
import moment from "moment";
import YoutubePlayer from "../YoutubePlayer";
import { accountHeaderMapping } from "./constants/accountHeaderMapping";
import { carrierHeaderMapping } from "./constants/carrierHeaderMapping";
import { contactHeaderMapping } from "./constants/contactHeaderMapping";
import { activityHeaderMapping } from "./constants/activityHeaderMapping";
import { laneHeaderMapping } from "./constants/laneHeaderMapping";
import AccountsImportTemplate from "../../documents/AccountsImportTemplate.csv";
import ActivitiesImportTemplate from "../../documents/ActivitiesImportTemplate.csv";
import ContactsImportTemplate from "../../documents/ContactsImportTemplate.csv";
import CarriersImportTemplate from "../../documents/CarriersImportTemplate.csv";
import { Check, AlertCircle, ArrowRight } from "lucide-react";
import "./ImportMapper.scss";
// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
} from "../../redux";
import RedirectToBillingModal from "./RedirectToBillingModal";
import ActivityIdentifierModal from "./ActivityIdentifierModal";
import LaneStatusFillModal from "./LaneStatusFillModal";
import { commonTerminologies } from "./commonTerminologies/commonTerminologies";

const date = new Date();
const myDate = moment(date).format("l");

class ImportPage extends Component {
  state = {
    format: "",
    data: [],
    fieldsData: [],
    headers: [],
    headersMapping: {},
    rows: [],
    isLoading: false,
    erroredData: [],
    totalCreated: 0,
    totalFailed: 0,
    tourComplete: false,
    showYoutubeVideo: false,
    reportCreated: false,
    reportData: {},
    reportError: false,
    showRedirectModal: false,
    recordsLimit: 50000,
    recordLimitCheck: false,
    importName: ``,
    activityRecordType: "",
    uniqueIdentifierFieldName: "",
    showActivityIdentifierModal: false,
    showLanesStatusModal: false,
    selectedLaneStatus: "",
  };
  fileInput = React.createRef();
  importType = window.location.pathname.split("/").at(-1);

  uniqueIdentifierMap = {
    accounts: [
      { label: "Account Id", value: "account_id" },
      { label: "Account Name", value: "account_name" },
      { label: "Account Email", value: "account_email" },
      { label: "Unique Identifier", value: "unique_identifier" },
    ],
    contacts: [
      { label: "Contact Id", value: "contact_id" },
      { label: "Contact Name", value: "contact_email" },
      { label: "Unique Identifier", value: "unique_identifier" },
    ],
    carriers: [
      { label: "Carrier Id", value: "carrier_id" },
      { label: "Carrier Name", value: "carrier_name" },
      { label: "MC#", value: "mc_number" },
      { label: "DOT#", value: "dot_number" },
      { label: "Unique Identifier", value: "unique_identifier" },
    ],
  };

  handleCloseRedirectModal = () => {
    this.setState({
      showRedirectModal: false,
    });
  };
  handleOpenRedirectModal = () => {
    this.setState({
      showRedirectModal: true,
    });
  };
  getRecordsLimit = () => {
    axios({
      method: "GET",
      url: `/company/check-records-limit`,
    }).then((res) => {
      this.setState({
        recordsLimit: res.data.records_limit - res.data.total_records_created,
        recordLimitCheck: true,
      });
    });
  };
  componentDidMount() {
    this.importType !== "activity" && this.getRecordsLimit();
    if (this.importType === "activity" || this.importType === "lane") {
      this.setState({
        showActivityIdentifierModal: true,
      });
    }
    axios({
      method: "GET",
      url: `${BASE_URL}/fields/${this.importType}`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((response) => {
        this.setState({
          fieldsData: response.data.fields,
        });
      })
      .catch((error) => console.log("error", error));
  }
  headersChangeHandler = (sheetHeader, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      headers: [...prevState.headers, value],
      headersMapping: { ...prevState.headersMapping, [sheetHeader]: value },
    }));
  };
  showRows = () => {
    this.setState((prevState) => ({
      rows: this.state.data.filter((d) => Object.keys(this.state.headers)),
    }));
  };

  activityIdentifierChangeHandler = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  mapHeaders = (headers, commonTerminologies) => {
    const headersMapping = {};

    headers.forEach((header) => {
      const normalizedHeader = header.toLowerCase().trim();
      let matchedField = null;

      // Match header with defined common terminologies
      for (const [field, aliases] of Object.entries(commonTerminologies)) {
        if (aliases.includes(normalizedHeader)) {
          matchedField = field;
          break;
        }
      }

      headersMapping[header] = matchedField || ""; // Default to "Do not import"
    });

    return headersMapping;
  };

  OnDrop = (file, e) => {
    this.setState({ tourComplete: true });
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let data = XLSX.utils.sheet_to_json(ws, {
        header: 0,
        defval: null,
      });
      if (data) {
        console.log("json import", data);
        if (
          this.importType !== "activity" &&
          this.importType !== "lane" &&
          data.length > this.state.recordsLimit
        ) {
          this.handleOpenRedirectModal();
          return;
        }
        if (data.length > 25000) {
          NotificationManager.error(
            "Please upload a csv file with less than 25,000 entries"
          );
          return;
        }
        if (
          data.length > 0 &&
          this.importType === "contact" &&
          (data[0]?.hasOwnProperty("First Name") ||
            data[0]?.hasOwnProperty("first name")) &&
          (data[0]?.hasOwnProperty("Last Name") ||
            data[0]?.hasOwnProperty("last name")) &&
          !(
            data[0]?.hasOwnProperty("Full Name") ||
            data[0]?.hasOwnProperty("full name")
          )
        ) {
          data = data?.map((d) => {
            // Check if both 'First Name' and 'Last Name' exist
            if (d["First Name"] && d["Last Name"]) {
              return {
                ...d,
                "Full Name": `${d["First Name"]}${
                  d["Middle Name"] ? " " + d["Middle Name"] : ""
                } ${d["Last Name"]}`, // Combine into 'Full Name'
              };
            }
            return d; // Return the object unchanged if one of the keys is missing
          });
        }
        const sheetHeaders = Object.keys(data[0]);

        // Map headers to internal fields
        const headersMapping = this.mapHeaders(
          sheetHeaders,
          commonTerminologies[this.importType]
        );
        const headers = Object.values(headersMapping).filter(
          (val) => val !== ""
        );
        console.log("check header", headers, headersMapping);
        this.setState({
          data,
          headersMapping,
          headers,
        });
      }
    };
    reader.readAsBinaryString(file.files[0]);
  };

  submitAttachment = (e) => {
    e.preventDefault();
    if (!this.state.importName) {
      NotificationManager.error("Import Name is mandatory");
      return false;
    }
    if (
      !Object.values(this.state.headersMapping).includes("name") &&
      this.importType !== "lane"
    ) {
      NotificationManager.error("Name field is mandatory");
      return false;
    }
    if (
      this.importType === "activity" &&
      !this.state.uniqueIdentifierFieldName
    ) {
      NotificationManager.error("Please select Activity Unique Identifier");
      return false;
    }
    if (this.importType === "lane" && !this.state.uniqueIdentifierFieldName) {
      NotificationManager.error("Please select Lane Unique Identifier");
      return false;
    }
    if (
      this.importType === "activity" &&
      !Object.values(this.state.headersMapping).includes(
        this.state.uniqueIdentifierFieldName
      )
    ) {
      NotificationManager.error("Please map Activity Unique Identifier");
      return false;
    }
    if (
      this.importType === "lane" &&
      !Object.values(this.state.headersMapping).includes(
        this.state.uniqueIdentifierFieldName
      )
    ) {
      NotificationManager.error("Please map Lane Unique Identifier");
      return false;
    }
    if (
      this.importType === "lane" &&
      !Object.values(this.state.headersMapping).includes("status") &&
      !this.state.selectedLaneStatus
    ) {
      this.setState({
        showLanesStatusModal: true,
      });
      return false;
    }
    if (
      this.importType === "lane" &&
      !Object.values(this.state.headersMapping).includes("status") &&
      this.state.selectedLaneStatus
    ) {
      this.setState({
        headersMapping: { ...this.state.headersMapping, status: "Status" },
      });
    }
    this.setState({ isLoading: true });
    const includeLaneStatusBySystem =
      this.importType === "lane" &&
      !Object.values(this.state.headersMapping).includes("status") &&
      this.state.selectedLaneStatus;
    const sheetHeaders = Object.values({
      ...this.state.headersMapping,
      ...(includeLaneStatusBySystem && { status: "status" }),
    })?.filter((key) => key !== "");
    // Function to check if a value is a Unix timestamp (10-digit for seconds or 13-digit for milliseconds)
    function isUnixTimestamp(value) {
      return (
        typeof value === "number" &&
        ((value >= 1000000000 && value <= 9999999999) ||
          (value >= 1000000000000 && value <= 9999999999999))
      );
    }

    // Function to check if a value is an Excel serial date
    function isExcelSerialDate(value) {
      return typeof value === "number" && value > 40000 && value < 60000;
    }

    // Function to check if a value is likely a phone number (e.g., 7 to 15 digits)
    function isPhoneNumber(value) {
      return (
        typeof value === "number" &&
        String(value).length >= 7 &&
        String(value).length <= 15
      );
    }

    // Function to convert Excel serial date to Unix timestamp (in seconds)
    function excelDateToUnix(excelDate) {
      return Math.floor((excelDate - 25569) * 86400);
    }

    // Function to check if a string is a valid date
    function isValidDateString(dateString) {
      // Check using Date.parse which returns NaN for invalid dates
      return !isNaN(Date.parse(dateString));
    }

    // Function to convert date string to Unix timestamp
    function dateStringToUnix(dateString) {
      const parsedDate = new Date(dateString);
      return Math.floor(parsedDate.getTime() / 1000); // Return Unix timestamp in seconds
    }

    // Main function to convert various date formats to Unix
    function convertToUnixIfDate(value) {
      // Ignore phone numbers and other non-date numbers
      if (isPhoneNumber(value)) {
        return value; // Return as-is, as it's not a date
      }

      // Case 1: Check if the value is a Unix timestamp (seconds or milliseconds)
      if (isUnixTimestamp(value)) {
        return value; // It's already in Unix format
      }

      // Case 2: Check if the value is an Excel serial date
      if (isExcelSerialDate(value)) {
        return excelDateToUnix(value); // Convert Excel serial date to Unix
      }

      // Case 3: Check if the value is a valid date string (ISO, YYYY-MM-DD, etc.)
      if (typeof value === "string" && isValidDateString(value)) {
        return dateStringToUnix(value); // Convert date string to Unix
      }

      // Case 4: if its another string then we want replace newline with spaces
      if (typeof value === "string" && !isValidDateString(value)) {
        return value.replace(/\r?\n|\r/g, " ");
      }

      // If the value is not a date, return it as is
      return value;
    }
    function adjustSheetHeader(sheetHeader, includeLaneStatus) {
      if (includeLaneStatus) {
        // Check if 'status' exists in the array
        const statusIndex = sheetHeader.indexOf("status");
        if (statusIndex > -1) {
          // Remove 'status' from its current position
          const [status] = sheetHeader.splice(statusIndex, 1);
          // Add 'status' to the end of the array
          sheetHeader.push(status);
        }
      }
      return sheetHeader;
    }
    const sheetData = this.state.data
      .map((obj) =>
        Object.keys(this.state.headersMapping)
          .filter((key) => this.state.headersMapping[key] !== "")
          .map((key) => obj[key])
          .map((cell) => {
            console.log("cell check", cell);
            if (this.importType !== "activity" && typeof cell === "string") {
              return cell.replace(/\r?\n|\r/g, " "); // Replace newline characters with spaces
            }
            return this.importType === "activity"
              ? convertToUnixIfDate(cell)
              : cell; // Leave non-string values unchanged
          })
      )
      ?.map((subArray) => {
        if (includeLaneStatusBySystem) {
          return [...subArray, this.state.selectedLaneStatus];
        } else {
          return subArray;
        }
      });
    const newSheetHeaders =
      this.importType === "lane"
        ? adjustSheetHeader(sheetHeaders, includeLaneStatusBySystem)
        : sheetHeaders;
    const fullSheet = [newSheetHeaders, ...sheetData];
    console.log("fullSheet", fullSheet);
    let dataInExcel = XLSX.utils.aoa_to_sheet(fullSheet);
    let dataInCSV = XLSX.utils.sheet_to_csv(dataInExcel, { FS: "," });
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, dataInExcel, "Test");
    console.log("errorDataInCSV:", dataInCSV);
    const filename = "import.csv";
    const formData = new FormData();
    const blob = new Blob([dataInCSV], { type: "text/csv;charset=utf-8" });
    blob.name = filename;
    formData.append("attachment", blob, filename);
    axios({
      method: "POST",
      url: `/attachments/file-upload`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        console.log("document uploaded", res);
        this.importData(res.data.s3_url);
      })
      .catch((err) => {
        const error = err?.response?.data.message || "Error importing";
        NotificationManager.error(error);
        this.setState({ isLoading: false });
      });
  };
  handleCloseFillStatusModal = (e) => {
    this.setState({
      showLanesStatusModal: false,
    });
    this.submitAttachment(e);
  };
  importData = (file_url) => {
    axios({
      method: "POST",
      url: `/import/records`,
      data: {
        name: this.state.importName,
        file_url,
        record_type: `${
          this.importType === "activity" ? "activitie" : this.importType
        }s`,
        ...(this.importType === "activity" && {
          activity_record_type: this.state.activityRecordType,
        }),
        ...(this.importType === "lane" && {
          lane_record_type: this.state.activityRecordType,
        }),
        ...((this.importType === "activity" || this.importType === "lane") && {
          unique_identifier_field_name: this.state.uniqueIdentifierFieldName,
        }),
      },
    })
      .then((res) => {
        this.setState({ isLoading: false });
        console.log("import response", res);
        NotificationManager.success(res.data?.message);
        this.props.history.push("/import");
      })
      .catch((err) => {
        const error = err?.response?.data.message || "Error importing";
        NotificationManager.error(error);
        this.setState({ isLoading: false });
      });
  };
  handleDownload = () => {
    // The relative path to the CSV file in the public directory
    const filePath =
      this.importType === "account"
        ? AccountsImportTemplate
        : this.importType === "carrier"
        ? CarriersImportTemplate
        : this.importType === "activity"
        ? ActivitiesImportTemplate
        : ContactsImportTemplate;

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = filePath;
    link.download = `${this.importType}ImportTemplate.csv`; // The name for the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    let dynamicFields = this.state.fieldsData
      .map((data) => data.fields)
      .flat(1)
      .reduce((obj, item) => ((obj[item.name] = item.label), obj), {});
    const uniqueIdentifierLabel = this.uniqueIdentifierMap[
      this.state.activityRecordType
    ]?.find(
      (type) => type.value === this.state.uniqueIdentifierFieldName
    )?.label;
    const headerMapping =
      this.importType === "account"
        ? accountHeaderMapping
        : this.importType === "carrier"
        ? carrierHeaderMapping
        : this.importType === "activity"
        ? activityHeaderMapping
        : this.importType === "lane"
        ? laneHeaderMapping
        : contactHeaderMapping;

    let headerKeyValueMappings = {
      ...headerMapping,
      ...dynamicFields,
      ...((this.importType === "activity" || this.importType === "lane") && {
        [this.state.uniqueIdentifierFieldName]: uniqueIdentifierLabel,
      }),
    };

    console.log(
      "headerMapping",
      this.state.headersMapping,
      this.state.data,
      this.state.headers
    );
    return (
      <div style={{ margin: "auto 30px" }}>
        {this.state.showYoutubeVideo && (
          <YoutubePlayer
            src="https://www.youtube.com/embed/dUY5d0OzQSw"
            closeIframe={() => this.setState({ showYoutubeVideo: false })}
          />
        )}
        <NotificationContainer />
        <RedirectToBillingModal
          showOpportunityModal={this.state.showRedirectModal}
          setShowOpportunityModal={this.handleOpenRedirectModal}
          handleCloseModal={this.handleCloseRedirectModal}
          userRole={this.props.authUser.role}
        />
        <form onSubmit={this.submitAttachment}>
          <div
            style={{
              borderBottom: "1px solid #E3E3E3",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "inline-block" }}>
              <h2 style={{ marginBottom: "0px" }}>
                Import{" "}
                {this.importType === "activity"
                  ? "Activitie"
                  : this.importType.charAt(0).toUpperCase() +
                    this.importType.slice(1)}
                s
                <button
                  type="button"
                  onClick={() => this.setState({ showYoutubeVideo: true })}
                  className="button-no-border-red"
                  style={{ color: "#61c99d", marginLeft: "20px" }}
                >
                  <i className="fa fa-video-camera" aria-hidden="true"></i>
                  &nbsp; Walk-through video
                </button>
                {/* <button onClick={this.handleDownload} className="button-sm">
                  Download Template
                </button> */}
              </h2>
              <p>
                Please upload a .csv or .xlsx file to import and map the fields
                for the data
              </p>
            </div>
            {this.fileInput?.current?.files[0] && (
              <div
                style={{
                  marginTop: "4vh",
                  float: "right",
                  marginRight: "30px",
                }}
              >
                <button
                  type="submit"
                  className="button-md"
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? "Please Wait..." : "Start Upload"}
                </button>
              </div>
            )}
          </div>
          <div
            style={
              this.importType === "activity" || this.importType === "lane"
                ? {
                    display: "flex",
                  }
                : {}
            }
          >
            <div className="controlsDashboard" style={{ marginTop: "2px" }}>
              <input
                value={this.state.importName}
                onChange={(e) =>
                  this.setState({
                    importName: e.target.value,
                  })
                }
                id="importName"
                className="floatLabel"
                style={{
                  fontSize: "15px",
                  borderRadius: "4px",
                  padding: "8px",
                  border: "1px solid #c5c5d3",
                  paddingTop: "13px",
                  paddingBottom: "5px",
                  minWidth: "250px",
                  marginRight: "10px",
                }}
              />
              <label
                for="importName"
                className="activeLabel"
                style={{ fontSize: "12px" }}
              >
                What would you like to name this import?
              </label>
            </div>
            {(this.importType === "activity" || this.importType === "lane") && (
              <div className="controlsDashboard" style={{ marginTop: "2px" }}>
                <select
                  className="floatLabel"
                  id="activityRecordType"
                  value={this.state.activityRecordType}
                  style={{
                    fontSize: "15px",
                    borderRadius: "4px",
                    padding: "8px",
                    border: "1px solid #c5c5d3",
                    paddingTop: "13px",
                    paddingBottom: "5px",
                    width: "250px",
                    marginRight: "10px",
                    height: "auto",
                  }}
                  onChange={(e) =>
                    this.setState({
                      activityRecordType: e.target.value,
                    })
                  }
                >
                  <option value="accounts">Accounts</option>
                  {this.importType === "activity" && (
                    <option value="contacts">Contacts</option>
                  )}
                  {this.importType === "lane" && (
                    <option value="carriers">Carriers</option>
                  )}
                </select>
                <label
                  for="activityRecordType"
                  className="activeLabel"
                  style={{ fontSize: "12px" }}
                >
                  Activity Record Type
                </label>
              </div>
            )}
            {(this.importType === "activity" || this.importType === "lane") && (
              <div className="controlsDashboard" style={{ marginTop: "2px" }}>
                <label
                  for="uniqueIdentifierFieldName"
                  className="activeLabel"
                  style={{ fontSize: "12px" }}
                >
                  Activity Unique Identifier
                </label>
                <select
                  className="floatLabel"
                  id="uniqueIdentifierFieldName"
                  style={{
                    fontSize: "15px",
                    borderRadius: "4px",
                    padding: "8px",
                    border: "1px solid #c5c5d3",
                    paddingTop: "13px",
                    paddingBottom: "5px",
                    width: "250px",
                    marginRight: "10px",
                    height: "auto",
                  }}
                  value={this.state.uniqueIdentifierFieldName}
                  onChange={(e) =>
                    this.setState({
                      uniqueIdentifierFieldName: e.target.value,
                    })
                  }
                >
                  <option value="">-</option>
                  {this.uniqueIdentifierMap[this.state.activityRecordType]?.map(
                    (type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}
          </div>
          <div style={{ margin: "15px 0" }}>
            <label
              htmlFor="file-upload"
              // className="tour-import-account"
              style={{
                textDecoration: "none",
                background:
                  !this.state.recordLimitCheck && this.importType !== "activity"
                    ? "#eee"
                    : "#FFFFFF",
                color: "#3AAB7B",
                padding: "10px 24px",
                borderRadius: "4px",
                border: "1px solid #3AAB7B",
                cursor: "pointer",
                display: "inline-block",
                marginRight: "10px",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "150%",
              }}
            >
              {!this.state.recordLimitCheck && this.importType !== "activity"
                ? "Checking Records Limit"
                : this.fileInput?.current?.files[0]?.name
                ? "File Name"
                : "Choose File"}
            </label>
            <input
              style={{ display: "none" }}
              disabled={
                !this.state.recordLimitCheck && this.importType !== "activity"
              }
              id="file-upload"
              type="file"
              ref={this.fileInput}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={() => this.OnDrop(this.fileInput.current)}
            />
            {this.fileInput?.current?.files[0]?.name}
          </div>
        </form>
        <TaskTourImport tourComplete={this.state.tourComplete} />
        {this.state.data.length > 0 && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "50% 50%",
              gridGap: "20px",
            }}
          >
            <div>
              <div className="import-card__header">
                <h2 className="import-card__title">Field Mapping</h2>
              </div>
              <div className="import-card__content">
                <div className="field-mapping">
                  <div className="field-mapping__header">
                    <div className="field-mapping__header-cell">
                      Spreadsheet Field
                    </div>
                    <div className="field-mapping__header-cell field-mapping__header-cell--center"></div>
                    <div className="field-mapping__header-cell">
                      Salesdash Field
                    </div>
                  </div>
                  <div
                    className="field-mapping"
                    style={{ height: "55vh", overflow: "auto" }}
                  >
                    {this.state.data.length > 0 &&
                      Object.keys(this.state.data[0]).map((key, i) => (
                        <div
                          key={key}
                          className={`field-mapping__row field-mapping__row--${
                            this.state.headersMapping[key] !== ""
                              ? "mapped"
                              : "unmapped"
                          }`}
                        >
                          <div className="field__source">
                            {this.state.headersMapping[key] !== "" ? (
                              <Check
                                className={`field__source-icon field__source-icon--mapped`}
                              />
                            ) : (
                              <AlertCircle
                                className={`field__source-icon field__source-icon--unmapped`}
                              />
                            )}
                            <span className="field__source-label">{key}</span>
                          </div>

                          <div className="field__mapping">
                            <ArrowRight
                              className={`field__mapping-arrow field__mapping-arrow--${
                                this.state.headersMapping[key] !== ""
                                  ? "mapped"
                                  : "unmapped"
                              }`}
                            />
                          </div>

                          <div className="field__target">
                            <select
                              name="headers"
                              onChange={(e) =>
                                this.headersChangeHandler(key, e)
                              }
                              style={{
                                border: "1px solid #A7ABAA",
                                borderRadius: "8px",
                                padding: "7px",
                                maxWidth: "210px",
                              }}
                              value={this.state.headersMapping[key] || ""}
                            >
                              <option value="">Do not import</option>
                              <optgroup label="Default Fields">
                                {Object.entries(headerMapping)
                                  .filter((acc) => {
                                    if (this.importType === "contact") {
                                      if (
                                        Object.values(
                                          this.state.headersMapping
                                        ).includes("account_name")
                                      ) {
                                        return acc[0] !== "carrier_name";
                                      } else if (
                                        Object.values(
                                          this.state.headersMapping
                                        ).includes("carrier_name")
                                      ) {
                                        return acc[0] !== "account_name";
                                      }
                                    }
                                    return acc;
                                  })
                                  // .filter((acc) => {
                                  //   if (this.importType === "account") {
                                  //     if (
                                  //       !this.props.companyOnboarding
                                  //         ?.is_multiple_account_owner_enabled
                                  //     ) {
                                  //       return acc[0] !== "shared_owners";
                                  //     }
                                  //   }
                                  //   return acc;
                                  // })
                                  .map((acc) => {
                                    return (
                                      (!Object.values(
                                        this.state.headersMapping
                                      ).includes(acc[0]) ||
                                        this.state.headersMapping[key] ===
                                          acc[0]) && (
                                        <option value={acc[0]}>{acc[1]}</option>
                                      )
                                    );
                                  })}
                                {(this.importType === "activity" ||
                                  this.importType === "lane") && (
                                  <option
                                    value={this.state.uniqueIdentifierFieldName}
                                  >
                                    {uniqueIdentifierLabel}
                                  </option>
                                )}
                              </optgroup>
                              {this.state.fieldsData.map((group) => {
                                const { label, fields, meta, id } = group;
                                return (
                                  (meta === null ||
                                    meta.visible_in_form === true) && (
                                    <optgroup label={label} key={id + label}>
                                      {fields?.map((field) => {
                                        const { id, label, name } = field;
                                        return (
                                          (!Object.values(
                                            this.state.headersMapping
                                          ).includes(name) ||
                                            this.state.headersMapping[key] ===
                                              name) && (
                                            <option key={id} value={name}>
                                              {label}
                                            </option>
                                          )
                                        );
                                      })}
                                    </optgroup>
                                  )
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="import-card__header">
                <h2 className="import-card__title">Preview Data</h2>
              </div>
              <div style={{ maxWidth: "48vw", overflow: "auto" }}>
                <table className="accountList__table">
                  <thead>
                    <tr>
                      {Object.values(this.state.headersMapping)
                        .filter((header) => header !== "")
                        .map((header) => {
                          return <th>{headerKeyValueMappings[header]}</th>;
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.slice(0, 5).map((obj, index) => (
                      <tr key={index}>
                        {Object.keys(this.state.headersMapping)
                          .filter(
                            (key) => this.state.headersMapping[key] !== ""
                          )
                          .map((key) => (
                            <td>
                              {obj[key]?.length > 35
                                ? obj[key]?.substring(0, 35) + "..."
                                : obj[key]}
                            </td>
                          ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {/* <div style={{ padding: "5px" }}>
          {this.state.data.length > 0 &&
            Object.keys(this.state.data[0]).map((key, i) => (
              <div
                key={i}
                style={{
                  display: "inline-block",
                  margin: "10px",
                  padding: "15px",
                  border: "2px solid #E3E3E3",
                  borderRadius: "8px",
                }}
              >
                <span>
                  <h4
                    style={{
                      margin: "6px auto",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "150%",
                      color: "#0C0D0D",
                    }}
                  >
                    Sheet field
                  </h4>{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "150%",
                      color: "#0C0D0D",
                    }}
                  >
                    {key}
                  </div>
                </span>
                <h4
                  style={{
                    margin: "6px auto",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "150%",
                    color: "#0C0D0D",
                  }}
                >
                  Salesdash field
                </h4>
                <select
                  name="headers"
                  onChange={(e) => this.headersChangeHandler(key, e)}
                  style={{
                    border: "1px solid #A7ABAA",
                    borderRadius: "8px",
                    padding: "7px",
                    maxWidth: "210px",
                  }}
                  value={this.state.headersMapping[key] || ""}
                >
                  <option value="">Do not import</option>
                  <optgroup label="Default Fields">
                    {Object.entries(headerMapping)
                      .filter((acc) => {
                        if (this.importType === "contact") {
                          if (
                            Object.values(this.state.headersMapping).includes(
                              "account_name"
                            )
                          ) {
                            return acc[0] !== "carrier_name";
                          } else if (
                            Object.values(this.state.headersMapping).includes(
                              "carrier_name"
                            )
                          ) {
                            return acc[0] !== "account_name";
                          }
                        }
                        return acc;
                      })
                      // .filter((acc) => {
                      //   if (this.importType === "account") {
                      //     if (
                      //       !this.props.companyOnboarding
                      //         ?.is_multiple_account_owner_enabled
                      //     ) {
                      //       return acc[0] !== "shared_owners";
                      //     }
                      //   }
                      //   return acc;
                      // })
                      .map((acc) => {
                        return (
                          (!Object.values(this.state.headersMapping).includes(
                            acc[0]
                          ) ||
                            this.state.headersMapping[key] === acc[0]) && (
                            <option value={acc[0]}>{acc[1]}</option>
                          )
                        );
                      })}
                    {(this.importType === "activity" ||
                      this.importType === "lane") && (
                      <option value={this.state.uniqueIdentifierFieldName}>
                        {uniqueIdentifierLabel}
                      </option>
                    )}
                  </optgroup>
                  {this.state.fieldsData.map((group) => {
                    const { label, fields, meta, id } = group;
                    return (
                      (meta === null || meta.visible_in_form === true) && (
                        <optgroup label={label} key={id + label}>
                          {fields?.map((field) => {
                            const { id, label, name } = field;
                            return (
                              (!Object.values(
                                this.state.headersMapping
                              ).includes(name) ||
                                this.state.headersMapping[key] === name) && (
                                <option key={id} value={name}>
                                  {label}
                                </option>
                              )
                            );
                          })}
                        </optgroup>
                      )
                    );
                  })}
                </select>
              </div>
            ))}
        </div> */}
        <ActivityIdentifierModal
          showModal={this.state.showActivityIdentifierModal}
          handleCloseModal={() =>
            this.setState({
              showActivityIdentifierModal: false,
            })
          }
          modalHeight={`34`}
          modalTop={`18`}
          importType={this.importType}
          activityRecordType={this.state.activityRecordType}
          uniqueIdentifierFieldName={this.state.uniqueIdentifierFieldName}
          activityIdentifierChangeHandler={this.activityIdentifierChangeHandler}
          uniqueIdentifierMap={this.uniqueIdentifierMap}
          importName={this.state.importName}
        />
        <LaneStatusFillModal
          showModal={this.state.showLanesStatusModal}
          handleCloseModal={this.handleCloseFillStatusModal}
          modalHeight={`34`}
          modalTop={`18`}
          activityIdentifierChangeHandler={this.activityIdentifierChangeHandler}
          selectedLaneStatus={this.state.selectedLaneStatus}
          allLaneStatuses={this.props.allLaneStatuses}
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    allLaneStatuses: state.allLaneStatuses.laneStatuses,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(ImportPage);
