import React, { useState, useCallback, useEffect, useRef } from "react";
import ActivityLog from "./ActivityLog";
import OpporOpportunityActivityForm from "./OpportunityActivityForm";
import "../sass/CustomerProfile.scss";
import OpportunitiesSection from "./OpportunitiesSection";
import axios from "axios";
import Confetti from "react-confetti";
import { NotificationManager } from "react-notifications";
import AllSequenceModal from "./Sequencing/AllSequenceModal";
import SaveEmailModal from "./SendEmail/SaveEmailModal";
import SendSequenceModal from "./Sequencing/SendSequenceModal";
import EditSequenceModal from "./Sequencing/EditSequenceModal";
import BillingCreditModal from "./Reports/BillingCreditModal";
import BillingRedirectModal from "./Reports/BillingRedirectModal";
import PostMarkAppModal from "./Reports/PostMarkAppModal";
import PostMarkAppVerificationModal from "./Reports/PostMarkAppVerificationModal";
import PersonalizedVarsModal from "./SendEmail/PersonalizedVarsModal";
import { withRouter } from "react-router-dom";

// Redux stuff
import { connect } from "react-redux";
import { authUserSet, setTab, setActiveTabIndex } from "../redux";
import NextRecordNavigation from "./NextRecordNavigation";

const OpportunitiesPage = ({
  opportunityId,
  customerName,
  opportunityName,
  authUser,
  activities,
  showTaskArrow,
  tasks,
  setTab,
  setActiveTabIndex,
  history,
  totalTabs,
  taskIndex,
}) => {
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showAllSequenceModal, setShowAllSequenceModal] = useState(false);
  const [showSaveEmailModal, setShowSaveEmailModal] = useState(false);
  const [showSendSequenceModal, setShowSendSequenceModal] = useState(false);
  const [showEditSequenceModal, setShowEditSequenceModal] = useState(false);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const [showPostMarkModal, setShowPostMarkModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showPersonalizedVarsModal, setShowPersonalizedVarsModal] =
    useState(false);

  const [logs, setLogs] = useState([]);
  const [pinnedActivity, setPinnedActivity] = useState(null);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [accountName, setAccountName] = useState("");
  const [accountId, setAccountId] = useState(null);
  const [contactName, setContactName] = useState("");
  const [contactId, setContactId] = useState(null);
  const [email, setEmail] = useState("");
  const [opportunityEmail, setOpportunityEmail] = useState(null);
  const [opportunityMobile, setOpportunityMobile] = useState("");
  const [contactEmailsArray, setContactEmailsArray] = useState([]);
  const [integration, setIntegration] = useState(null);
  const [accountData, setAccountData] = useState({});
  const [accountCustomFields, setAccountCustomFields] = useState({});
  const [signatureId, setSignatureId] = useState(null);
  const [signatureVerified, setSignatureVerified] = useState(false);
  const [emailCredits, setEmailCredits] = useState(0);
  const [selectedSequence, setSelectedSequence] = useState({});
  const [templateId, setTemplateId] = useState(null);
  const [expand, setExpand] = useState(false);
  const [personalizedVariablesArray, setPersonalizedVariablesArray] = useState(
    []
  );
  const [recorderLoading, setRecorderLoading] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [activityLoader, setActivityLoader] = useState(false);
  const [emailSignature, setEmailSignature] = useState("");
  const [contacts, setContacts] = useState([]);
  const opportunityMobileRef = useRef(opportunityMobile);
  const setOpportunityMobileRef = (data) => {
    opportunityMobileRef.current = data;
    setOpportunityMobile(data);
  };

  const emailDecision = () => {
    if (signatureId === null) {
      setShowPostMarkModal(true);
    } else if (!signatureVerified) {
      setShowVerificationModal(true);
    } else if (emailCredits === null || 10 > emailCredits) {
      setShowCreditsModal(true);
    } else {
      setShowAllSequenceModal(true);
    }
  };
  const getSignature = () => {
    axios({
      method: "GET",
      url: `/users/email-signature`,
    }).then((res) => {
      if (res.data.email_signature !== null) {
        setEmailSignature(res.data.email_signature);
      }
    });
  };
  const recordChangeHandler = (e) => {
    setOpportunityEmail(e.target.value);
  };
  const fetchAllContactsOpportunities = () => {
    axios({
      method: "GET",
      url: `/opportunities/${opportunityId}/contacts?_limit=100`,
    }).then((res) => {
      const data = res.data.contacts;
      setContacts(data);
    });
  };
  const recordSubmitHandler = (e) => {
    e.preventDefault();
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      opportunityEmail !== null &&
      opportunityEmail !== "" &&
      !emailValidator.test(opportunityEmail)
    ) {
      setRecorderLoading(false);
      setEmailValidationError(true);
      return false;
    }
    setRecorderLoading(true);
    let customField = accountCustomFields;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    axios({
      method: "PUT",
      url: `/opportunities/${opportunityId}`,
      data: {
        ...(accountData.accountId && { account_id: accountData.accountId }),
        name: accountData.name,
        revenue: accountData.revenue,
        stage_id: accountData.stage_id,
        funnel_id: accountData.funnel_id,
        close_date: accountData.close_date,
        description: accountData.description,
        opportunity_owner_id: accountData.opportunity_owner_id,
        close_reason: accountData.close_reason_value,
        custom_fields: customField,
        email: accountData.email,
        formatted_phone:
          accountData.formatted_phone === undefined ||
          accountData.formatted_phone === "undefined"
            ? null
            : accountData.formatted_phone !== null
            ? accountData.formatted_phone.toString()
            : accountData.formatted_phone,
        website:
          accountData.website === null
            ? null
            : accountData.website.startsWith("https://") ||
              accountData.website.startsWith("http://")
            ? accountData.website
            : "https://" + accountData.website,
      },
    })
      .then((response) => {
        NotificationManager.success("Deal edited successfully.");
        setRecorderLoading(false);
        setEmailValidationError(false);
        setShowSaveEmailModal(false);
        setShowAllSequenceModal(true);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing deal.");
        }
      });
  };

  const checkUserProfile = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      setSignatureVerified(res.data.postmark_signature_verified);
      setSignatureId(res.data.postmark_signature_id);
      setEmailCredits(res.data.postmark_email_balance);
    });
  };
  const checkVerification = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      verifySignature(res.data.postmark_signature_verified);
      setSignatureVerified(res.data.postmark_signature_verified);
      setSignatureId(res.data.postmark_signature_id);
      setEmailCredits(res.data.postmark_email_balance);
    });
  };
  const verifySignature = (signatureVerified) => {
    if (!signatureVerified) {
      axios({
        method: "PATCH",
        url: `/users/verify-postmark-signature`,
        data: {},
      })
        .then((res) => {
          setShowVerificationModal(false);
          checkUserProfile();
          setShowCreditsModal(true);
        })
        .catch((err) => {
          NotificationManager.error("Email not verified.");
        });
    }
  };
  const setSequenceValue = (value) => {
    axios({
      method: "GET",
      url: `/sequences/${value}?opportunity_id=${opportunityId}`,
    }).then((res) => {
      setSelectedSequence(res.data.sequence);
      setShowSendSequenceModal(true);
      setShowAllSequenceModal(false);
    });
  };
  const readTemplateVarsValues = (tempId, personalizedVariables) => {
    let url = `/emails/templates/${tempId}?opportunity_id=${opportunityId}`;
    axios({
      method: "GET",
      url,
    }).then((res) => {
      setShowPersonalizedVarsModal(false);
      let finalContent = res.data.template.body;
      let finalSubject = res.data.template.subject;
      for (let i = 0; i < personalizedVariables.length; i++) {
        finalContent = finalContent.includes(
          `{{ ${personalizedVariables[i].key} }}`
        )
          ? finalContent.replaceAll(
              `{{ ${personalizedVariables[i].key} }}`,
              personalizedVariables[i].value
            )
          : finalContent;
        finalSubject = finalSubject.includes(
          `{{ ${personalizedVariables[i].key} }}`
        )
          ? finalSubject.replaceAll(
              `{{ ${personalizedVariables[i].key} }}`,
              personalizedVariables[i].value
            )
          : finalSubject;
      }
    });
  };
  const opportunityInfo = () => {
    axios({
      method: "GET",
      url: `/opportunities/${opportunityId}`,
    }).then((res) => {
      setOpportunityMobileRef(res.data.opportunity?.formatted_phone);
      setAccountName(res.data.opportunity?.account_name);
      setAccountId(res.data.opportunity?.account_id);
      setContactName(res.data.opportunity?.contact_name);
      setContactId(res.data.opportunity?.contact_id);
      res.data.opportunity.account_id &&
        getEmail(res.data.opportunity.account_id);
    });
  };
  useEffect(() => {
    checkUserProfile();
    getSignature();
    opportunityInfo();
    fetchAllContactsOpportunities();
  }, []);

  const loadActivities = useCallback(
    (page, fetchType) => {
      setActivityLoader(true);
      let url = `/activities/timeline-activities?opportunity_id=${opportunityId}&_limit=30&_page=${page}`;
      axios({
        method: "GET",
        url,
      })
        .then((res) => {
          setActivityLoader(false);
          if (res.data.activities.length === 0) setHasMoreItems(false);
          switch (fetchType) {
            case "refetch": {
              setLogs(res.data.activities);
              setHasMoreItems(false);
              setPinnedActivity(res.data.pinned_activity);
              break;
            }
            case "initial":
              setLogs((oldLogs) => [...oldLogs, ...res.data.activities]);
              setHasMoreItems(false);
              setPinnedActivity(res.data.pinned_activity);
              break;
            case "nextPage":
            default:
              setLogs((oldLogs) => [...oldLogs, ...res.data.activities]);
              setPinnedActivity(res.data.pinned_activity);
              if (res.data.activities.length === 0) {
                setHasMoreItems(false);
              } else {
                setHasMoreItems(true);
              }
          }
        })
        .catch((err) => {
          setActivityLoader(false);
        });
    },
    [opportunityId]
  );
  const getEmail = (id) => {
    axios({
      method: "GET",
      url: `/accounts/${id}`,
    }).then((res) => setEmail(res.data.account.email));
  };
  useEffect(() => {
    loadActivities(1, "initial");
  }, [loadActivities]);
  useEffect(() => {
    if (activities.fetchActivity === true) {
      loadActivities(1, "refetch");
    }
  }, [activities.fetchActivity]);
  const getConfetti = () => {
    setShowConfetti(true);
  };
  return (
    <div>
      <div
        className="customerProfile"
        id={`opportunityProfile${opportunityId}`}
      >
        {showConfetti && (
          <Confetti
            width={window.innerWidth / 1.1}
            height={window.innerHeight}
            numberOfPieces={400}
            recycle={false}
          />
        )}
        <div className="row">
          {showTaskArrow && (
            <NextRecordNavigation
              tasks={tasks}
              setTab={setTab}
              setActiveTabIndex={setActiveTabIndex}
              history={history}
              totalTabs={totalTabs}
              recordType="opportunity"
              recordId={opportunityId}
              taskIndex={taskIndex}
            />
          )}
          <div className="col-1-of-3">
            <OpportunitiesSection
              customerId={accountId}
              opportunityId={opportunityId}
              customerName={accountName}
              contactId={contactId}
              contactName={contactName}
              showTaskModal={showTaskModal}
              setShowTaskModal={setShowTaskModal}
              opportunityName={opportunityName}
              loadActivities={loadActivities}
              email={email}
              getConfetti={getConfetti}
              setOpportunityEmail={setOpportunityEmail}
              setContactEmailsArray={setContactEmailsArray}
              setIntegration={setIntegration}
              setAccountData={setAccountData}
              setAccountCustomFields={setAccountCustomFields}
              fetchAllContactsOpportunities={fetchAllContactsOpportunities}
              contacts={contacts}
            />
          </div>
          {showAllSequenceModal && (
            <AllSequenceModal
              showOpportunityModal={showAllSequenceModal}
              setShowOpportunityModal={() => setShowAllSequenceModal(true)}
              handleCloseModal={() => setShowAllSequenceModal(false)}
              setSequenceValue={setSequenceValue}
              handleOpenEditSequenceModal={() => setShowEditSequenceModal(true)}
            />
          )}
          {showSaveEmailModal && (
            <SaveEmailModal
              showModal={showSaveEmailModal}
              handleCloseModal={() => setShowSaveEmailModal(false)}
              email={opportunityEmail}
              changeHandler={recordChangeHandler}
              modalHeight={45}
              modalTop={15}
              loading={recorderLoading}
              submitHandler={recordSubmitHandler}
              error={emailValidationError}
            />
          )}
          {showSendSequenceModal && (
            <SendSequenceModal
              showOpportunityModal={showSendSequenceModal}
              setShowOpportunityModal={() => setShowSendSequenceModal(true)}
              handleCloseModal={() => setShowSendSequenceModal(false)}
              selectedSequence={selectedSequence}
              toEmail={opportunityEmail ? [opportunityEmail] : []}
              opportunityId={opportunityId}
              fromEmail={authUser.email}
              handleCloseEmailModal={() => setShowSendSequenceModal(false)}
              emailType={"opportunity_id"}
              loadActivities={loadActivities}
              emailSignature={emailSignature}
              metadata={{
                type: "opportunity",
                type_id: opportunityId,
                type_name: opportunityName,
              }}
            />
          )}
          {showEditSequenceModal && (
            <EditSequenceModal
              showOpportunityModal={showEditSequenceModal}
              setShowOpportunityModal={() => setShowEditSequenceModal(true)}
              handleCloseModal={() => setShowEditSequenceModal(false)}
              selectedSequence={selectedSequence}
            />
          )}
          {showCreditsModal && (
            <BillingCreditModal
              showOpportunityModal={showCreditsModal}
              setShowOpportunityModal={() => setShowCreditsModal(true)}
              handleCloseModal={() => setShowCreditsModal(false)}
              handleEmailOpenModal={() => setShowAllSequenceModal(true)}
              checkUserProfile={checkUserProfile}
            />
          )}
          {showRedirectModal && (
            <BillingRedirectModal
              showOpportunityModal={showRedirectModal}
              setShowOpportunityModal={() => setShowRedirectModal(true)}
              handleCloseModal={() => setShowRedirectModal(false)}
            />
          )}
          {showPostMarkModal && (
            <PostMarkAppModal
              showOpportunityModal={showPostMarkModal}
              setShowOpportunityModal={() => setShowPostMarkModal(true)}
              handleCloseModal={() => setShowPostMarkModal(false)}
              handleVerificationOpenModal={() => setShowVerificationModal(true)}
              checkUserProfile={checkUserProfile}
            />
          )}
          {showVerificationModal && (
            <PostMarkAppVerificationModal
              showOpportunityModal={showVerificationModal}
              setShowOpportunityModal={() => setShowVerificationModal(true)}
              handleCloseModal={() => setShowVerificationModal(false)}
              checkUserProfile={checkUserProfile}
              signatureVerified={signatureVerified}
              handleEmailOpenModal={() => setShowAllSequenceModal(true)}
              checkVerification={checkVerification}
            />
          )}
          {showPersonalizedVarsModal && (
            <PersonalizedVarsModal
              showOpportunityModal={showPersonalizedVarsModal}
              setShowOpportunityModal={() => setShowPersonalizedVarsModal(true)}
              handleCloseModal={() => setShowPersonalizedVarsModal(false)}
              readTemplateVarsValues={readTemplateVarsValues}
              templateId={templateId}
              personalizedVariablesArray={personalizedVariablesArray}
            />
          )}
          <div className="col-1-of-3">
            <div className="activityLog">
              <div className="activityLog__header">
                <div>
                  <h3 className="activity-title">Activity Timeline</h3>
                </div>
                <div className=" activityLog__menu">
                  <button
                    type="button"
                    onClick={() => setExpand(!expand)}
                    className="activityLog__button"
                  >
                    {expand === false ? "Expand All" : "Collapse All"}
                  </button>
                </div>
              </div>
              <ActivityLog
                customerId={accountId}
                contactId={contactId}
                entityId={opportunityId}
                entityType="opportunity"
                customerName={customerName}
                // New props
                loadActivities={loadActivities}
                hasMoreItems={hasMoreItems}
                logs={logs}
                opportunityName={opportunityName}
                integration={integration}
                pinnedActivity={pinnedActivity}
                activityLoader={activityLoader}
                setHasMoreItems={setHasMoreItems}
                toEmail={opportunityEmail ? [opportunityEmail] : []}
                expand={expand}
                setExpand={setExpand}
              />
            </div>
          </div>
          <div className="col-1-of-3">
            <OpporOpportunityActivityForm
              customerId={accountId}
              contactId={contactId}
              contactName={contactName}
              opportunityId={opportunityId}
              showTaskModal={showTaskModal}
              setShowTaskModal={setShowTaskModal}
              customerName={accountName}
              opportunityName={opportunityName}
              // New props
              loadActivities={loadActivities}
              opportunityEmail={opportunityEmail}
              contactEmailsArray={contactEmailsArray}
              integration={integration}
              emailDecision={emailDecision}
              fetchAllContactsOpportunities={fetchAllContactsOpportunities}
              contacts={contacts}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
    activities: state.activities,
    totalTabs: state.tabsState.tabs.length,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
  };
};

export default connect(MSP, MDP)(withRouter(OpportunitiesPage));
