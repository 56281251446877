import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";
import "../sass/ReactTabs.scss";
import CustomerProfile from "./CustomerProfile";
import OpportunitiesPage from "./OpportunitiesPage";
import OpportunityNavbar from "../images/DealsTabIcon.svg";
import ReportNavbar from "../images/ReportsNavIcon.svg";
import AccountNavbar from "../images/AccountsNavIcon.svg";
import CarrierTabIcon from "../images/CarrierTabIcon.svg";
import ContactNavbar from "../images/ContactsNavIcon.svg";
import TaskTabIcon from "../images/TaskTabIcon.svg";
import ActivityTabIcon from "../images/ActivityTabIcon.svg";
import LaneTabIcon from "../images/LaneTabIcon.svg";
import { withRouter } from "react-router-dom";
import DashboardLogo from "../images/DashboardWhite.svg";
import DismissIcon from "../images/Dismiss.svg";
import { Route } from "react-router-dom";

// Redux stuff
import { connect } from "react-redux";
import { deleteAllTabs, deleteTab, setActiveTabIndex } from "../redux";
import { compose } from "recompose";
import Dashboard from "./Dashboard";
import ReportsTable from "./Reports/ReportsTable";
import { Visible } from "react-grid-system";
import ContactsProfile from "./Contacts/ContactsProfile";
import TasksList from "./Tasks/TasksList";
import ActivityList from "./ActivityList";
import DealsTabList from "./Deals/DealsTabList";
import ContactsList from "./Contacts/ContactsList";
import AccountsList from "./AccountsList";
import CampaignPage from "./Campaign/CampaignPage";
import DeleteAllTabsModal from "./DeleteAllTabsModal";
import LeadFunnelReport from "./LeadFunnel/LeadFunnelReport";
import HottesstFollowUpReport from "./HottestFollowup/HottesstFollowUpReport";
import CampaignMetricesReport from "./Campaign/CampaignMetricesReport";
import SequenceMetricesReport from "./Sequencing/SequenceMetricesReport";
import CarrierProfile from "./Carrier/CarrierProfile";
import CarriersList from "./Carrier/CarriersList";
import UnassignedLeadsList from "./UnassignedLeads/UnassignedLeadsList";
import LanesList from "./Lanes/LanesList";
import PipelineStageChangesPage from "./DashboardCustom/PipelineStageChanges/PipelineStageChangesPage";
import LanesMapList from "./Lanes/LanesMapList";
import AccountStatusChangesPage from "./DashboardCustom/AccountStatusChange/AccountStatusChangesPage";
import SequenceHistoryPage from "./Sequencing/SequenceHistoryPage";

const TabBar = React.memo((props) => {
  const [tabIndex, setTabIndex] = useState(props.activeTabIndex);
  const [showCloseModal, setShowCloseModal] = useState(false);

  useEffect(() => {
    setTabIndex(props.activeTabIndex);
  }, [props.activeTabIndex]);

  const {
    tabs = [],
    deleteTab,
    setActiveTabIndex,
    activeTabIndex,
    deleteAllTabs,
  } = props;

  return (
    <Tabs
      selectedIndex={tabIndex}
      onSelect={(tabIndex) => {
        setTabIndex(tabIndex);
        if (tabIndex !== activeTabIndex) {
          setActiveTabIndex(tabIndex);
        }
        props.history.push("/active-tabs");
      }}
      // forceRenderTabPanel={true}
    >
      <Visible lg xl xxl>
        <TabList>
          <Tab>
            <img src={DashboardLogo} alt="" width="12px" />
            &nbsp;{" "}
          </Tab>
          {tabs?.map((tab, index) => {
            switch (tab.type) {
              case "dashboard":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={DashboardLogo} alt="" width="12px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 14
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 5
                        ? tab.name.substring(0, 5)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "account":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={AccountNavbar} alt="" width="12px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 14
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 5
                        ? tab.name.substring(0, 5)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "carrier":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={CarrierTabIcon} alt="" width="12px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 14
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 5
                        ? tab.name.substring(0, 5)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "accountList":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={AccountNavbar} alt="" width="12px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 14
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 5
                        ? tab.name.substring(0, 5)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "unassignedList":
              case "accountStatusChangeReport":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={AccountNavbar} alt="" width="12px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 14
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 5
                        ? tab.name.substring(0, 5)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "carrierList":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={CarrierTabIcon} alt="" width="12px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 14
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 5
                        ? tab.name.substring(0, 5)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "laneList":
              case "laneMapList":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img
                      src={LaneTabIcon}
                      alt=""
                      width="14px"
                      style={{ transform: "translateY(2px)" }}
                    />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 14
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 5
                        ? tab.name.substring(0, 5)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "opportunity":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={OpportunityNavbar} alt="" width="12px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "report":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={ReportNavbar} alt="" width="12px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "followUp":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={ReportNavbar} alt="" width="12px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "campaign":
              case "sequenceHistory":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={ReportNavbar} alt="" width="12px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "contact":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={ContactNavbar} alt="" width="14px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "contactList":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={ContactNavbar} alt="" width="14px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "task":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={TaskTabIcon} alt="" width="14px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "activity":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={ActivityTabIcon} alt="" width="14px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "dealStage":
              case "pipelineStageChangeReport":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={OpportunityNavbar} alt="" width="14px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "leadFunnel":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={ReportNavbar} alt="" width="14px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "emailCampaignMetrices":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={ReportNavbar} alt="" width="14px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              case "sequenceCampaignMetrices":
                return (
                  <Tab key={`${tab.type}_${tab.id}`}>
                    <img src={ReportNavbar} alt="" width="14px" />
                    &nbsp;
                    {tabs.length > 23
                      ? ""
                      : tabs.length > 20
                      ? tab.name.length > 2
                        ? tab.name.substring(0, 1)
                        : tab.name
                      : tabs.length > 10
                      ? tab.name.length > 3
                        ? tab.name.substring(0, 2)
                        : tab.name
                      : tab.name.length > 10
                      ? tab.name.substring(0, 9)
                      : tab.name}
                    &nbsp; &nbsp;{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: "4.5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        deleteTab(index);
                      }}
                    >
                      x
                    </span>
                  </Tab>
                );
              default:
                return null;
            }
          })}
          {tabs.length > 8 && (
            <button
              onClick={() => setShowCloseModal(true)}
              style={{
                cursor: "pointer",
                background: "#a7abaa",
                border: "none",
                outline: "none",
                borderRadius: "5px 5px 0 0",
              }}
              className="closeAllTabButton"
            >
              <img src={DismissIcon} alt="X" height="16px" width="16px" />
            </button>
          )}
        </TabList>
      </Visible>

      <Route path="/active-tabs">
        <TabPanel key={`defaultDashboard`}>
          <Dashboard defaultDashboard={true} />
        </TabPanel>
        {tabs?.map((tab, index) => {
          switch (tab.type) {
            case "dashboard":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <Dashboard
                    dashboardId={tab.id}
                    tabIndex={index}
                    deleteTab={deleteTab}
                    settings={tab.settings}
                    type={tab.dashboardType}
                    teamId={tab.teamId}
                  />
                </TabPanel>
              );
            case "account":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <CustomerProfile
                    accountId={tab.id}
                    tabIndex={index}
                    deleteTab={deleteTab}
                    showTaskArrow={tab.showTaskArrow}
                    taskIndex={tab.taskIndex}
                    tasks={tab.tasks}
                  />
                </TabPanel>
              );
            case "carrier":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <CarrierProfile
                    carrierId={tab.id}
                    tabIndex={index}
                    deleteTab={deleteTab}
                    showTaskArrow={tab.showTaskArrow}
                    tasks={tab.tasks}
                    taskIndex={tab.taskIndex}
                  />
                </TabPanel>
              );
            case "opportunity":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <OpportunitiesPage
                    opportunityName={tab.name}
                    opportunityId={tab.id}
                    accountId={tab.account_id}
                    showTaskArrow={tab.showTaskArrow}
                    tasks={tab.tasks}
                    taskIndex={tab.taskIndex}
                  />
                </TabPanel>
              );
            case "report":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <ReportsTable
                    reportId={tab.id}
                    reportName={tab.name}
                    deleteTabIndex={index}
                    deleteTab={deleteTab}
                  />
                </TabPanel>
              );
            case "campaign":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <CampaignPage campaignId={tab.id} campaignName={tab.name} />
                </TabPanel>
              );
            case "sequenceHistory":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <SequenceHistoryPage id={tab.id} name={tab.name} />
                </TabPanel>
              );
            case "contact":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <ContactsProfile
                    contactId={tab.id}
                    tabIndex={index}
                    deleteTab={deleteTab}
                    showTaskArrow={tab.showTaskArrow}
                    tasks={tab.tasks}
                    taskIndex={tab.taskIndex}
                  />
                </TabPanel>
              );
            case "contactList":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <ContactsList contactId={tab.id} />
                </TabPanel>
              );
            case "accountList":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <AccountsList accountId={tab.id} />
                </TabPanel>
              );
            case "unassignedList":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <UnassignedLeadsList accountId={tab.id} />
                </TabPanel>
              );
            case "carrierList":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <CarriersList accountId={tab.id} />
                </TabPanel>
              );
            case "laneList":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <LanesList id={tab.id} />
                </TabPanel>
              );
            case "laneMapList":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <LanesMapList id={tab.id} />
                </TabPanel>
              );
            case "task":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <TasksList
                    taskId={tab.id}
                    taskType={tab.taskType}
                    length={tab.length}
                    userId={tab.userId}
                    userName={tab.userName}
                  />
                </TabPanel>
              );
            case "followUp":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <HottesstFollowUpReport
                    id={tab.id}
                    type={tab.followUpType}
                    length={tab.length}
                    userId={tab.userId}
                    userName={tab.userName}
                  />
                </TabPanel>
              );
            case "activity":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <ActivityList
                    activityId={tab.id}
                    userId={tab.userId}
                    userName={tab.userName}
                    period={tab.activities_period}
                  />
                </TabPanel>
              );
            case "dealStage":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <DealsTabList
                    dealId={tab.id}
                    userId={tab.userId}
                    userName={tab.userName}
                    period={tab.dealPeriod}
                    stageId={tab.stageId}
                    funnelId={tab.funnelId}
                    closeDatePeriod={tab.closeDatePeriod}
                  />
                </TabPanel>
              );
            case "pipelineStageChangeReport":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <PipelineStageChangesPage
                    dealId={tab.id}
                    userId={tab.userId}
                    userName={tab.userName}
                  />
                </TabPanel>
              );
            case "accountStatusChangeReport":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <AccountStatusChangesPage
                    dealId={tab.id}
                    userId={tab.userId}
                    userName={tab.userName}
                  />
                </TabPanel>
              );
            case "leadFunnel":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <LeadFunnelReport
                    leadId={tab.id}
                    userId={tab.userId}
                    userName={tab.userName}
                    status={tab.status}
                    type={tab.statusType}
                    allStatuses={tab.allStatuses}
                  />
                </TabPanel>
              );
            case "emailCampaignMetrices":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <CampaignMetricesReport
                    type={tab.campaignType}
                    name={tab.name}
                    time={tab.time}
                    start_time={tab.start_time}
                    end_time={tab.end_time}
                  />
                </TabPanel>
              );
            case "sequenceCampaignMetrices":
              return (
                <TabPanel key={`${tab.type}_${tab.id}`}>
                  <SequenceMetricesReport
                    type={tab.campaignType}
                    name={tab.name}
                    time={tab.time}
                    start_time={tab.start_time}
                    end_time={tab.end_time}
                  />
                </TabPanel>
              );
            default:
              return null;
          }
        })}
      </Route>
      <DeleteAllTabsModal
        showOpportunityModal={showCloseModal}
        setShowOpportunityModal={() => setShowCloseModal(true)}
        handleCloseModal={() => setShowCloseModal(false)}
        deleteAllTabs={deleteAllTabs}
      />
    </Tabs>
  );
});

const MSP = (state) => {
  return {
    tabs: state.tabsState.tabs,
    activeTabIndex: state.tabsState.activeTabIndex,
  };
};

const MDP = (dispatch) => {
  return {
    deleteTab: (idx) => dispatch(deleteTab(idx)),
    deleteAllTabs: (idx) => dispatch(deleteAllTabs(idx)),
    setActiveTabIndex: (idx) => dispatch(setActiveTabIndex(idx)),
  };
};

export default compose(withRouter, connect(MSP, MDP))(TabBar);
