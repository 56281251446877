import React, { Component } from "react";
import axios from "axios";
import "../../sass/AccountsList.scss";
import Pagination from "react-js-pagination";
import moment from "moment";
import { debounce } from "lodash";
import { Col, Row } from "react-grid-system";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { colourStyles } from "../../constants/selectStyles";
// import GoogleAutocompleteComponent from "../GoogleComponents/GoogleAutocompleteComponent";
import { AsYouType } from "libphonenumber-js";
import {
  usStatesList,
  usStatesListWithTimeZones,
} from "../../constants/usStatesList";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
  fetchAllEquipmentTypes,
  fetchAllSpecialRequirements,
  setReportsFilters,
} from "../../redux";
import { getCurrencyValue } from "../../utils/Helper/reusableFunctions";
import { CurrencyList } from "../../constants/currencylist";
import TransferAccountOwnershipModal from "../Reports/TransferAccountOwnershipModal";

const shotclockFilters = [
  // { label: "-Select-", value: "" },
  { label: "Last 7 days", value: "last_7_days" },
  { label: "Last 14 days", value: "last_14_days" },
  { label: "Last 30 days", value: "last_30_days" },
  { label: "Last 60 days", value: "last_60_days" },
];

class UnassignedLeadsList extends Component {
  state = {
    accounts: [],
    query: "",
    activePage: 1,
    total: 0,
    showContactModal: false,
    showNewAccountModal: false,
    sortDirection: true,
    isApplying: true,
    inputFocused: {
      isTrue: false,
      id: "",
      name: "",
    },
    showNameInput: {
      isTrue: false,
      id: "",
      name: "",
    },
    origin: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    destination: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    price: null,
    equipmentTypes: [],
    speciaRequirements: [],
    modes: [],
    daysOfWeek: [],
    transferAccount: [],
    excludeArray: [],
    allReportTransfer: false,
    showOwnerModal: false,
    ownerErrorMessage: false,
    customFieldArray: [],
    statesFilter: [],
    industryFilter: [],
    timezoneFilter: [],
    statusFilter: [],
    shotclockFilter: "last_7_days",
    isShotClockEnabled: false,
    status: "",
  };
  days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  handleOpenContactModal = () => {
    this.setState({
      showContactModal: true,
    });
  };
  handleCloseContactModal = () => {
    this.setState({
      showContactModal: false,
    });
  };
  handleOpenNewAccountModal = () => {
    this.setState({
      showNewAccountModal: true,
    });
  };
  handleCloseNewAccountModal = () => {
    this.setState({
      showNewAccountModal: false,
    });
  };
  handleOwnerOpenModal = () => {
    this.setState({
      showOwnerModal: true,
    });
  };
  handleOwnerCloseModal = () => {
    this.setState({
      showOwnerModal: false,
    });
  };

  fetchAccounts = debounce((query, page = 1, sortKey = "created_date") => {
    const {
      origin,
      destination,
      equipmentTypes,
      speciaRequirements,
      modes,
      price,
      daysOfWeek,
      statesFilter,
      industryFilter,
      timezoneFilter,
      shotclockFilter,
      isShotClockEnabled,
      statusFilter,
    } = this.state;
    let url = `/accounts?_limit=30&_page=${page}&unassigned_leads=${true}`;
    if (query) {
      url += `&name=${encodeURIComponent(query)}`;
    }
    if (sortKey) {
      const dir = this.state.sortDirection === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    if (origin.city) {
      url += `&origin_city=${origin.city}`;
    } else if (origin.zip) {
      url += `&origin_zip=${origin.zip}`;
    } else if (origin.state) {
      url += `&origin_state=${origin.state}`;
    }
    if (destination.city) {
      url += `&destination_city=${destination.city}`;
    } else if (destination.zip) {
      url += `&destination_zip=${destination.zip}`;
    } else if (destination.state) {
      url += `&destination_state=${destination.state}`;
    }
    if (equipmentTypes.length > 0) {
      url += `&equipment_type_ids=${equipmentTypes
        ?.map((type) => type.value)
        .join(",")}`;
    }
    if (speciaRequirements.length > 0) {
      url += `&requirement_type_ids=${speciaRequirements
        ?.map((type) => type.value)
        .join(",")}`;
    }
    if (modes.length > 0) {
      url += `&mode_ids=${modes?.map((type) => type.value).join(",")}`;
    }
    if (daysOfWeek.length > 0) {
      url += `&days_of_week=${daysOfWeek.join(",")}`;
    }
    if (price) {
      url += `&price=${price}`;
    }
    if (isShotClockEnabled) {
      url += `&shotclock=${isShotClockEnabled}&duration=${shotclockFilter}`;
    }
    if (industryFilter?.length > 0) {
      url += `&cf_industry=${encodeURIComponent(
        industryFilter?.map((type) => type.value).join(",")
      )}`;
    }
    if (statusFilter?.length > 0) {
      url += `&status=${encodeURIComponent(
        statusFilter?.map((type) => type.value).join(",")
      )}`;
    }
    if (statesFilter?.length > 0 || timezoneFilter?.length > 0) {
      let states = [];
      if (timezoneFilter?.length > 0) {
        const arr = timezoneFilter?.flatMap((zone) => zone.value);
        states = arr.filter((item, index) => arr.indexOf(item) === index);
      }
      if (statesFilter?.length > 0) {
        const newStates = states.concat(
          statesFilter?.map((state) => state.value)
        );
        states = newStates.filter(
          (item, index) => newStates.indexOf(item) === index
        );
      }
      url += `&state=${encodeURIComponent(states.join(","))}`;
    }
    this.props.setReportsFilters({
      id: "UnassignedLeadsList",
      filters: {
        query,
        activePage: page,
        sortKey,
        sortDirection: this.state.sortDirection,
        origin,
        destination,
        equipmentTypes,
        speciaRequirements,
        modes,
        price,
        daysOfWeek,
        industryFilter,
        statesFilter,
        statusFilter,
        timezoneFilter,
        shotclockFilter,
        isShotClockEnabled,
      },
    });
    this.setState({ isApplying: true });
    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        this.setState({
          accounts: response.data.accounts,
          total: response.data.total,
          isApplying: false,
        });
      })
      .catch((error) => this.setState({ isApplying: false }));
  }, 500);
  daysOfWeekChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      const newDays = [
        ...this.state.daysOfWeek.filter((day) => day !== ""),
        value,
      ];
      this.setState({
        daysOfWeek: newDays,
      });
    } else {
      const newDays = this.state.daysOfWeek.filter((pc) => pc !== value);
      this.setState({
        daysOfWeek: newDays,
      });
    }
  };
  fetchAllReportsFilters = () => {
    const selectedReport = this.props.reportsFilters?.find(
      (report) => report.id === "UnassignedLeadsList"
    );
    if (this.props.reportsFilters && selectedReport) {
      const {
        query,
        activePage,
        sortKey,
        sortDirection,
        origin,
        destination,
        equipmentTypes,
        speciaRequirements,
        modes,
        price,
        daysOfWeek,
        timezoneFilter,
        shotclockFilter,
        isShotClockEnabled,
        industryFilter,
        statesFilter,
        statusFilter,
      } = selectedReport.filters;
      this.setState(
        {
          query,
          activePage,
          sortKey,
          sortDirection,
          origin,
          destination,
          equipmentTypes,
          speciaRequirements,
          modes,
          price,
          daysOfWeek,
          timezoneFilter,
          shotclockFilter,
          isShotClockEnabled,
          industryFilter,
          statesFilter,
          statusFilter,
        },
        () =>
          this.fetchAccounts(
            this.state.query,
            this.state.activePage,
            this.state.sortKey
          )
      );
    } else {
      this.fetchAccounts();
    }
  };
  componentDidMount() {
    this.fetchAllReportsFilters();
    this.getCustomFields();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.origin.address !== prevState.origin.address ||
      this.state.destination.address !== prevState.destination.address ||
      this.state.price !== prevState.price ||
      this.state.shotclockFilter !== prevState.shotclockFilter ||
      this.state.isShotClockEnabled !== prevState.isShotClockEnabled ||
      this.state.equipmentTypes?.length !== prevState.equipmentTypes?.length ||
      this.state.speciaRequirements?.length !==
        prevState.speciaRequirements?.length ||
      this.state.modes?.length !== prevState.modes?.length ||
      this.state.industryFilter?.length !== prevState.industryFilter?.length ||
      this.state.statesFilter?.length !== prevState.statesFilter?.length ||
      this.state.statusFilter?.length !== prevState.statusFilter?.length ||
      this.state.timezoneFilter?.length !== prevState.timezoneFilter?.length ||
      this.state.daysOfWeek?.length !== prevState.daysOfWeek?.length
    ) {
      this.fetchAccounts(this.state.query, 1, this.state.sortKey);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.handleClickToDial);
  }
  handleOriginPlaceSelect = (place) => {
    this.setState({
      origin: place,
    });
  };
  handleDestinationPlaceSelect = (place) => {
    this.setState({
      destination: place,
    });
  };
  changeFilterHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  tableHeaders = [
    {
      value: "account_name",
      label: "Name",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "website",
      label: "website",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "state",
      label: "state",
      sort: true,
      editable: true,
      type: "dropdown",
    },
    {
      value: "last_activity_date",
      label: "last Activity Date",
      sort: true,
      editable: false,
      type: "date",
    },
    {
      value: "cf_industry",
      label: "industry",
      sort: true,
      editable: true,
      type: "dropdown",
      custom: true,
    },
    {
      value: "status",
      label: "Status",
      sort: true,
      editable: true,
      type: "dropdown",
    },
    {
      value: "cf_employees",
      label: "Employee",
      sort: true,
      editable: true,
      type: "text",
      custom: true,
    },
    {
      value: "cf_annual_revenue",
      label: "Annual Revenue",
      sort: true,
      editable: true,
      type: "decimal",
      custom: true,
    },
  ];

  renderTableData = (accountData, tableData, name, value, dateFormat) => {
    let newTabData = {
      type: "account",
      id: accountData.id,
      name: accountData.name,
    };
    let renderItem = <td>{value}</td>;
    if (name === "name") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { cursor: "pointer", color: "#4A9876" }
              }
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  this.props.setTab({ ...newTabData, blank: true });
                } else {
                  this.props.setActiveTabIndex(this.props.totalTabs + 1);
                  this.props.setTab(newTabData);
                  this.props.history.push("/active-tabs");
                }
              }}
            >
              {value}
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: accountData.id,
                    name,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === accountData.id &&
                this.state.showNameInput.isTrue === true &&
                this.state.showNameInput.name === name
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(accountData, name, e.target.value)
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                    name: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (name === "cf_annual_revenue") {
      const currency = this.props.companyOnboarding?.currency;
      const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
      renderItem = (
        <td>
          {value
            ? currencySymbol.symbol +
              getCurrencyValue(value)?.toLocaleString("en-US")
            : null}
        </td>
      );
    } else if (name === "website") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { cursor: "pointer", color: "#4A9876" }
              }
            >
              <a
                href={
                  value
                    ? value.startsWith("https://") ||
                      value.startsWith("http://")
                      ? value
                      : "https://" + value
                    : null
                }
                target="_blank"
                rel="noreferrer"
                style={{
                  cursor: "pointer",
                  color: "#4A9876",
                }}
              >
                {value}
              </a>
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === accountData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name: name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: accountData.id,
                    name,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === accountData.id &&
                this.state.showNameInput.isTrue === true &&
                this.state.showNameInput.name === name
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(accountData, name, e.target.value)
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: accountData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                    name: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (tableData.type === "text" || tableData.type === "decimal") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === accountData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <input
            className="inlineReportEdit-input"
            type={tableData.type === "decimal" ? "number" : "text"}
            value={value}
            onChange={(e) =>
              this.fieldChangeHandler(
                accountData,
                name,
                e.target.value,
                tableData.custom
              )
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: accountData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    } else if (tableData.type === "date") {
      renderItem = <td>{value && moment(value).format(dateFormat)}</td>;
    } else if (tableData.type === "dropdown" && name === "status") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === accountData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <select
            className="inlineReportEdit-input"
            value={this.state.status}
            onChange={(e) => {
              this.fieldChangeHandler(
                accountData,
                name,
                e.target.value,
                tableData.custom
              );
              this.statusChangeHandler(e);
            }}
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: accountData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          >
            <option value="" selected disabled>
              -Select-
            </option>
            {this.props.allAccountStatuses?.map((status) => (
              <option
                style={{ textTransform: "capitalize" }}
                key={status.id}
                value={status.name}
              >
                {status.name}
              </option>
            ))}
          </select>
        </td>
      );
    } else if (tableData.type === "dropdown") {
      const industryOptions = this.state.customFieldArray?.find(
        (field) => field.key === "cf_industry"
      );
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === accountData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <select
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) =>
              this.fieldChangeHandler(
                accountData,
                name,
                e.target.value,
                tableData.custom
              )
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: accountData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          >
            <option value="" selected disabled>
              -Select-
            </option>
            {name === "state" &&
              usStatesList?.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            {name !== "state" &&
              industryOptions &&
              industryOptions?.choices?.map((choice) => (
                <option key={choice.id} value={choice.value}>
                  {choice.value}
                </option>
              ))}
          </select>
        </td>
      );
    }

    return renderItem;
  };
  fieldChangeHandler = (account, name, value, isCustom) => {
    let accountData = account;
    let formattedValue = value;
    if (name === "formatted_phone") {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      formattedValue = formatter.input(value); // Format the input value
    }
    accountData = isCustom
      ? {
          ...account,
          custom_fields: {
            ...account.custom_fields,
            [name]: formattedValue === "" ? null : formattedValue,
          },
        }
      : {
          ...account,
          [name]: formattedValue === "" ? null : formattedValue,
        };
    this.setState({
      accounts: this.state.accounts?.map((acc) => {
        if (acc.id === account.id) {
          if (isCustom) {
            acc = {
              ...account,
              custom_fields: {
                ...account.custom_fields,
                [name]: formattedValue === "" ? null : formattedValue,
              },
            };
          } else {
            acc = {
              ...account,
              [name]: formattedValue === "" ? null : formattedValue,
            };
          }
        }
        return acc;
      }),
    });
    this.submitHandler(accountData);
  };
  getCustomFields = () => {
    this.setState({
      customFields: this.props.allAccountCustomFields,
    });
    const customField = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field, index) => {
            const { label, name, id, type, choices } = field;
            return {
              name: label,
              key: name,
              custom: true,
              id,
              type,
              choices,
              editable: true,
            };
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const columnsArray = this.state.columnsArray;
    const customFieldTypeCheck = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.setState({
      // columnsArray: columnsArray.concat(customFieldArray),
      customFieldArray: customFieldArray,
      customFieldRevenueTypeCheck: objTypeCheck,
    });
  };
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };
  statusChangeHandler = (e) => {
    const { value } = e.target;

    this.setState({
      status: value,
    });
    axios({
      method: "PUT",
      url: `/accounts/${this.state.inputFocused?.id}/status`,
      data: {
        status: value,
      },
    })
      .then((res) => {
        // NotificationManager.success("Successfully changed status");
      })
      .catch((err) => {
        NotificationManager.error("Error changing status");
      });
  };

  submitHandler = debounce((accountData) => {
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i;
    if (
      accountData.formatted_phone !== null &&
      accountData.formatted_phone !== "" &&
      !phoneValidator.test(accountData.formatted_phone)
    ) {
      return false;
    }
    if (
      accountData.email !== null &&
      accountData.email !== "" &&
      !emailValidator.test(accountData.email)
    ) {
      return false;
    }
    if (!accountData.name) {
      return false;
    }
    axios({
      method: "PUT",
      url: `/accounts/${accountData.id}`,
      data: {
        name: accountData.name,
        state: accountData.state,
        country: accountData.country,
        zip: accountData.zip,
        city: accountData.city,
        address: accountData.address,
        source: accountData.source,
        email: accountData.email,
        formatted_phone: accountData.formatted_phone,
        phone_ext: accountData.phone_ext,
        status: accountData.status,
        website: accountData.website
          ? accountData.website.startsWith("https://") ||
            accountData.website.startsWith("http://")
            ? accountData.website
            : "https://" + accountData.website
          : null,
        description: accountData.description,
        preferred_communication: accountData.preferred_communication?.filter(
          (pref) => pref !== ""
        ),
        custom_fields: accountData.custom_fields,
      },
    })
      .then((response) => {
        // NotificationManager.success("Account edited successfully.");
      })
      .catch((error) => {
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing account.");
        }
      });
  }, 500);
  allReportTransfer = () => {
    this.setState({
      allReportTransfer: !this.state.allReportTransfer,
      transferAccount: [],
      excludeArray: [],
    });
  };
  transferAccountChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.name === "transferAccount") {
      if (e.target.checked === true) {
        this.setState((prevState) => ({
          transferAccount: [
            ...this.state.transferAccount.filter((com) => com !== ""),
            value,
          ],
        }));
      } else {
        this.setState({
          transferAccount: this.state.transferAccount.filter(
            (pc) => pc !== value
          ),
        });
      }
    }
  };
  excludeAccountChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === false) {
      this.setState((prevState) => ({
        excludeArray: [
          ...this.state.excludeArray.filter((com) => com !== ""),
          value,
        ],
      }));
    } else {
      this.setState({
        excludeArray: this.state.excludeArray.filter((pc) => pc !== value),
      });
    }
  };
  transferOwnership = (primaryOwnerId, sharedAccountOwners) => {
    if (primaryOwnerId === null) {
      this.setState({ ownerErrorMessage: true });
      return false;
    }
    const filters = {
      name: this.state.query,
      origin_city: this.state.origin.city,
      origin_zip: this.state.origin.zip,
      origin_state: this.state.origin.state,
      destination_city: this.state.destination.city,
      destination_zip: this.state.destination.zip,
      destination_state: this.state.destination.state,
      equipment_type_ids:
        this.state.equipmentTypes.length === 0
          ? null
          : this.state.equipmentTypes?.map((type) => type.value).join(","),
      requirement_type_ids:
        this.state.speciaRequirements.length === 0
          ? null
          : this.state.speciaRequirements?.map((type) => type.value).join(","),
      mode_ids:
        this.state.modes.length === 0
          ? null
          : this.state.modes?.map((type) => type.value).join(","),
      cf_industry:
        this.state.industryFilter?.length === 0
          ? null
          : this.state.industryFilter?.map((type) => type.value).join(","),
      state:
        this.state.statesFilter?.length === 0
          ? null
          : this.state.statesFilter?.map((type) => type.value).join(","),
      status:
        this.state.statusFilter?.length === 0
          ? null
          : this.state.statusFilter?.map((type) => type.value).join(","),
      days_of_week:
        this.state.daysOfWeek.length === 0
          ? null
          : this.state.daysOfWeek.join(","),
      price: this.state.price,
    };
    axios({
      method: "POST",
      url: `accounts/unassigned-leads/bulk-transfer`,
      data: {
        ...(this.state.allReportTransfer && {
          filters: Object.entries(filters).reduce((acc, [key, value]) => {
            if (value !== null && value !== "" && value !== undefined) {
              acc[key] = value;
            }
            return acc;
          }, {}),
        }),
        excluded_ids: this.state.excludeArray?.map((id) => parseInt(id)),
        owners: sharedAccountOwners
          ?.map((user) => ({
            is_primary: false,
            owner_id: user.value,
          }))
          .concat([
            {
              is_primary: true,
              owner_id: primaryOwnerId,
            },
          ]),
        ...(!this.state.allReportTransfer && {
          account_ids: this.state.transferAccount,
        }),
      },
    })
      .then((res) => {
        this.fetchAccounts();
        this.handleOwnerCloseModal();
        this.setState({
          transferAccount: [],
          allReportTransfer: false,
          excludeArray: [],
        });
        NotificationManager.success("Ownership transferred successfully.");
      })
      .catch((err) =>
        NotificationManager.error("Error transferring ownership")
      );
  };

  render() {
    const accounts = this.state.accounts;
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const customFieldHeaders = this.state.customFieldArray?.map(
      (arr) => arr.key
    );
    const filteredHeaders = this.tableHeaders
      ?.map((header) => {
        if (header.custom) {
          return customFieldHeaders.includes(header.value) ? header : undefined;
        } else {
          return header;
        }
      })
      ?.filter((header) => header !== undefined);
    const industryArray = this.state.customFieldArray?.find(
      (custom) => custom.key === "cf_industry"
    );
    return (
      <div className="accountList">
        <h1 className="accountList__heading">
          Unassigned Leads{" "}
          <span style={{ color: "#4A9876" }}>{this.state.total}</span>
        </h1>
        <div className="accountList__function">
          <input
            type="search"
            placeholder="&#xf002;&nbsp;&nbsp;&nbsp;Search Accounts"
            className="reports-search"
            value={this.state.query}
            onChange={({ target: { value } }) => {
              this.fetchAccounts(value, 1, this.state.sortKey);
              this.setState({ query: value, activePage: 1 });
            }}
          />
          <button
            type="button"
            onClick={this.handleOwnerOpenModal}
            disabled={
              this.state.total === 0 ||
              (this.state.allReportTransfer === false &&
                this.state.transferAccount.length === 0)
            }
            className="button-md-black-outline"
            style={{
              margin: "10px 0",
              display: "block",
            }}
          >
            Transfer Unassigned Leads
          </button>
        </div>
        <div className="lanes-panel-form-1">
          <div style={{ marginRight: "20px" }}>
            <label class="container-checkbox" style={{ paddingLeft: "25px" }}>
              <input
                id={`shotClockSettingCheckbox`}
                type="checkbox"
                value={this.state.isShotClockEnabled}
                checked={this.state.isShotClockEnabled}
                onChange={() =>
                  this.setState({
                    isShotClockEnabled: !this.state.isShotClockEnabled,
                  })
                }
                style={{
                  width: "20px",
                  display: "inline-block",
                  cursor: "not-allowed",
                }}
              />
              <span class="checkmark"></span>
              Show Shot Clock Violation Accounts
            </label>
          </div>
          {this.state.isShotClockEnabled && (
            <div className="lanes-panel-form-1-div">
              <label>Duration</label>
              <select
                style={{
                  backgroundColor: "white",
                  outline: "#4A9876",
                  margin: "5px auto",
                  height: "37px",
                  boxShadow: "none",
                  width: "200px",
                  border: "1px solid rgb(204, 204, 204)",
                  borderRadius: "4px",
                  padding: "2px 8px",
                  fontSize: "16px",
                }}
                value={this.state.shotclockFilter}
                onChange={(e) =>
                  this.setState({
                    shotclockFilter: e.target.value,
                  })
                }
              >
                {shotclockFilters?.map((time) => (
                  <option value={time.value} key={time.value}>
                    {time.label}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div className="lanes-panel-form-1">
          <div className="lanes-panel-form-1-div">
            <label>Industry</label>
            <Select
              value={this.state.industryFilter}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={industryArray?.choices?.map((type) => {
                return { value: type.value, label: type.value };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                this.setState({
                  industryFilter: selectedOptions,
                })
              }
            />
          </div>
          <div className="lanes-panel-form-1-div">
            <label>State</label>
            <CreatableSelect
              isMulti
              value={this.state.statesFilter}
              formatCreateLabel={(inputText) => `Add "${inputText}"`}
              onChange={(selectedOptions) =>
                this.setState({
                  statesFilter: selectedOptions,
                })
              }
              options={usStatesList?.map((status) => {
                return {
                  label: status,
                  value: status,
                };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              styles={colourStyles}
              placeholder="-Select-"
            />
          </div>
          <div className="lanes-panel-form-1-div">
            <label>Timezone</label>
            <Select
              isMulti
              value={this.state.timezoneFilter}
              onChange={(selectedOptions) =>
                this.setState({
                  timezoneFilter: selectedOptions,
                })
              }
              options={usStatesListWithTimeZones?.map((time) => {
                return {
                  label: time.label,
                  value: time.states,
                };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              styles={colourStyles}
              placeholder="-Select-"
            />
          </div>
          <div className="lanes-panel-form-1-div">
            <label>Status</label>
            <Select
              isMulti
              value={this.state.statusFilter}
              formatCreateLabel={(inputText) => `Add "${inputText}"`}
              onChange={(selectedOptions) =>
                this.setState({
                  statusFilter: selectedOptions,
                })
              }
              options={this.props.allAccountStatuses?.map((status) => {
                return {
                  label: status.name,
                  value: status.name,
                };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              styles={colourStyles}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </div>
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchAccounts(this.state.query, page, this.state.sortKey);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
        {this.state.isApplying ? (
          <div class="load-wrapp">
            <div class="load-3">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
        ) : this.state.isApplying === false && this.state.total === 0 ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "50px",
              fontWeight: "500",
            }}
          >
            No accounts found
          </div>
        ) : (
          <table className="accountList__tableReport">
            <thead>
              <tr>
                <th style={{ width: "8px" }}>
                  {/* <input
                    type="checkbox"
                    style={{ width: "12px", height: "12px" }}
                    onClick={this.allReportTransfer}
                  /> */}
                </th>
                {filteredHeaders.map((headers) => (
                  <th key={headers.label}>
                    <Row>
                      <Col lg={9} xl={9}>
                        {headers.label}
                      </Col>
                      <Col lg={3} xl={3}>
                        {headers.sort && (
                          <button
                            type="button"
                            style={{
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                              outline: "none",
                              color: "#657885",
                            }}
                            onClick={() =>
                              this.setState(
                                {
                                  sortDirection: !this.state.sortDirection,
                                  sortKey: headers.value,
                                },
                                this.fetchAccounts(
                                  this.state.query,
                                  this.state.activePage,
                                  headers.value
                                )
                              )
                            }
                          >
                            <i className="fa fa-long-arrow-up"></i>
                            <i className="fa fa-long-arrow-down"></i>
                          </button>
                        )}
                      </Col>
                    </Row>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {accounts?.map((account) => {
                return (
                  <tr key={account.id} style={{ textTransform: "capitalize" }}>
                    <td style={{ width: "8px" }}>
                      {this.state.allReportTransfer ? (
                        <input
                          type="checkbox"
                          style={{ width: "12px", height: "12px" }}
                          name="transferAccount"
                          value={account.id}
                          checked={
                            !this.state.excludeArray.includes(
                              account.id.toString()
                            )
                          }
                          onClick={this.excludeAccountChangeHandler}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          style={{ width: "12px", height: "12px" }}
                          name="transferAccount"
                          value={account.id}
                          onClick={this.transferAccountChangeHandler}
                        />
                      )}
                    </td>
                    {filteredHeaders?.map((header) =>
                      this.renderTableData(
                        account,
                        header,
                        header.value === "account_name" ? "name" : header.value,
                        account[
                          header.value === "account_name"
                            ? "name"
                            : header.value
                        ] || account.custom_fields[header.value],
                        dateFormat
                      )
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchAccounts(this.state.query, page, this.state.sortKey);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
        <TransferAccountOwnershipModal
          showOpportunityModal={this.state.showOwnerModal}
          setShowOpportunityModal={this.handleOwnerOpenModal}
          handleCloseModal={this.handleOwnerCloseModal}
          transferOwnership={this.transferOwnership}
          ownerErrorMessage={this.state.ownerErrorMessage}
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    isAccountFieldsModified: state.allAccountFields.isModified,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    reportsFilters: state.reportsFilters.reports,
    allAccountCustomFields: state.allAccountCustomFields.fields,
    allAccountStatuses: state.allAccountStatuses.accountStatuses,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    fetchAllEquipmentTypes: (equipmentTypes) =>
      dispatch(fetchAllEquipmentTypes(equipmentTypes)),
    fetchAllSpecialRequirements: (specialRequirementsData) =>
      dispatch(fetchAllSpecialRequirements(specialRequirementsData)),
    setReportsFilters: (reportsFilters) =>
      dispatch(setReportsFilters(reportsFilters)),
  };
};

export default connect(MSP, MDP)(UnassignedLeadsList);
